import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Project from './Project';
import Task from './Task';
import Sprint from './Sprint';
import Report from './Report';
import User from './User';
import Service from './Service';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  projects: Project,
  task: Task,
  sprints: Sprint,
  reports: Report,
  users: User,
  services: Service,
});
