import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    UPDATE_AUTH,
    UPDATE_PROFILE,
    ON_LABEL_MENU_ITEM_SELECT,
    GET_MARK_AS_START,
    ON_SAVE_PROFILE,
    SET_FAVOURITE_PROJECT,
    SET_FAVOURITE_SPRINT,
    ON_FILTER_ADD,
    ON_FILTER_ADD_SUCCESS,
    ON_FILTER_REMOVE,
    ON_RATE_MANAGER,
    ON_RATE_MANAGER_SUCCESS
} from 'constants/ActionTypes';

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};
export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};
export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

export const userSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser
    }
};
export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};


export const userGoogleSignIn = () => {
    return {
        type: SIGNIN_GOOGLE_USER
    };
};
export const userGoogleSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GOOGLE_USER_SUCCESS,
        payload: authUser
    };
};
export const userFacebookSignIn = () => {
    return {
        type: SIGNIN_FACEBOOK_USER
    };
};
export const userFacebookSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_FACEBOOK_USER_SUCCESS,
        payload: authUser
    };
};
export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const userTwitterSignIn = () => {
    return {
        type: SIGNIN_TWITTER_USER
    };
};
export const userTwitterSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_TWITTER_USER_SUCCESS,
        payload: authUser
    };
};
export const userGithubSignIn = () => {
    return {
        type: SIGNIN_GITHUB_USER
    };
};
export const userGithubSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GITHUB_USER_SUCCESS,
        payload: authUser
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};
export const updateAuth = (user) => {
    return {
        type: UPDATE_AUTH,
        payload: user
    };
};
export const updateProfile = (profile) => {
    return {
        type: UPDATE_PROFILE,
        payload: profile
    };
};

export const onSaveProfile = (profile) => {
    return {
        type: ON_SAVE_PROFILE,
        payload: profile
    };
};

export const onLabelMenuItemSelect = (label, tasks) => {
    return {
        type: ON_LABEL_MENU_ITEM_SELECT,
        payload: {label, tasks}
    };
};

export const onMarkAsStart = (task, favourite) => {
    return {
        type: GET_MARK_AS_START,
        payload: { task, favourite }
    };
};

export const onFavouriteProject = (project, favourite) => {
    return {
        type: SET_FAVOURITE_PROJECT,
        payload: { project, favourite }
    };
};

export const onFavouriteSprint = (sprint, favourite) => {
    return {
        type: SET_FAVOURITE_SPRINT,
        payload: { sprint, favourite }
    };
};

export const onFilterAdd = (filter) => {
    return {
        type: ON_FILTER_ADD,
        payload: {filter}
    };
};
export const onFilterAddSuccess = (filter) => {
    return {
        type: ON_FILTER_ADD_SUCCESS,
        payload: {filter}
    };
};

export const onFilterRemove = (filterName) => {
    return {
        type: ON_FILTER_REMOVE,
        payload: {filterName}
    };
};

export const onRateManager = (rating) => {
    return {
        type: ON_RATE_MANAGER,
        payload: rating,
    };
};

export const onRateManagerSuccess = (rating) => {
    return {
        type: ON_RATE_MANAGER_SUCCESS,
        payload: rating,
    };
};