export const serviceToType = (serviceCategory) => {
    console.log(serviceCategory);
    switch (serviceCategory){
        case 'SEO':
            return 'SEO';
        case 'PPC':
            return 'PPC';
        case 'Reputation':
            return 'SERM';
        case 'Programming':
            return 'Programming';
        case 'Copywriting':
            return 'Writing';
        case 'Content':
            return 'Content';
        default:
            return 'Organization';
    }
};

export const typeToService = (typeName) => {
    switch (typeName){
        case 'SEO':
            return 'SEO';
        case 'PPC':
            return 'PPC';
        case 'SERM':
            return 'Reputation';
        case 'Programming':
            return 'Programming';
        case 'Writing':
            return 'Copywriting';
        case 'Content':
            return 'Content';
        default:
            return 'Organization';
    }
};