import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    UPDATE_AUTH,
    UPDATE_PROFILE,
    ON_LABEL_MENU_ITEM_SELECT,
    GET_MARK_AS_START,
    ON_SAVE_PROFILE,
    SET_FAVOURITE_PROJECT,
    SET_FAVOURITE_SPRINT,
    ON_FILTER_ADD_SUCCESS,
    ON_FILTER_REMOVE,
    ON_RATE_MANAGER_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: localStorage.getItem('user_id'),
    profileLoaded: false,
    photoURL: ""
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload.authUser,
                displayName: action.payload.displayName
            }
        }
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                ...action.payload
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                roles: null,
                photoURL: null,
                displayName: null,
                initURL: '/app/tasks-assigned',
                loader: false
            }
        }
        case UPDATE_AUTH: {
            return {
                ...state
            }
        }
        case UPDATE_PROFILE: {
            return {
                ...state,
                ...action.payload,
                loader: false,
                profileLoaded: true,
            }
        }
        case ON_SAVE_PROFILE: {
            return {
                ...state,
                displayName: action.payload.displayName,
            }
        }
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                ...action.payload
            }
        }
        case SIGNIN_FACEBOOK_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_TWITTER_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_GITHUB_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case ON_LABEL_MENU_ITEM_SELECT: {
            const tasks_labels = state.tasks_labels;
            for (let [key, task] of Object.entries(action.payload.tasks)) {
                tasks_labels[task.id] = action.payload.label.title;
            }
            return {
                ...state,
                tasks_labels
            }
        }
        case GET_MARK_AS_START: {
            const tasks_favourite = state.tasks_favourite ? state.tasks_favourite : {};
            tasks_favourite[action.payload.task.id] = action.payload.favourite;
            return {
                ...state,
               tasks_favourite
            }
        }
        case SET_FAVOURITE_PROJECT: {
            const projects_favourite = state.projects_favourite ? state.projects_favourite : {};
            projects_favourite[action.payload.project.id] = action.payload.favourite;
            return {
                ...state,
                projects_favourite
            }
        }

        case SET_FAVOURITE_SPRINT: {
            const sprints_favourite = state.sprints_favourite ? state.sprints_favourite : {};
            sprints_favourite[action.payload.sprint.id] = action.payload.favourite;
            return {
                ...state,
                sprints_favourite
            }
        }

        case ON_FILTER_ADD_SUCCESS: {
            const {filter} = action.payload;
            const filters = {...state.filters};
            filters[filter.subject] = filter;
            return {
                ...state,
                filters: filters
            }
        }

        case ON_FILTER_REMOVE: {
            const {filterName} = action.payload;
            const filters = [];
            for (let [key, filter] of Object.entries(state.filters)) {
                if(filterName !== filter.subject)
                    filters[filter.subject] = filter;
            }
            return {
                ...state,
                filters: filters,
            }
        }

        case ON_RATE_MANAGER_SUCCESS: {
            const {manager, rating} = action.payload;
            return {
                ...state,
                lastManagerRating: {manager, rating, rateDate: {seconds: Math.floor(Date.now()/1000)}},
            }
        }

        default:
            return state;
    }
}
