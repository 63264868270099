import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar'
import {useDispatch, useSelector} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut, onSaveProfile} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import Profile from './Profile';
import IconButton from "@material-ui/core/IconButton";
import {Grid} from "@material-ui/core";
import {toggleCollapsedNav} from 'actions/Setting';

const UserInfo = () => {

  const dispatch = useDispatch();

  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const userName = useSelector(state => state.auth.displayName);
  //const user = useSelector(state => state.auth);
  const user = useSelector(({auth}) => auth);
  const {navCollapsed, drawerType, width, navigationStyle} = useSelector(({settings}) => settings);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const onSaveProfileFunction = (data) => {
      dispatch(onSaveProfile(data));
  };

    const onToggleCollapsedNav = (e) => {
        const val = !navCollapsed;
        dispatch(toggleCollapsedNav(val));
    };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
        <Grid container className="user-detail" alignItems="center">
            <Grid item xs={3}>
                <Avatar
                    alt='...'
                    src={user.photoURL}
                    className="user-avatar "
                />
            </Grid>
            <Grid item xs={7}>
                <h4 className="user-name" onClick={handleClick}>
                    { userName }
                    <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
                </h4>
            </Grid>
            <Grid item xs={2} onClick={onToggleCollapsedNav}>
                { width < 1200 &&
                    <IconButton>
                        <i className="zmdi zmdi-close-circle text-white"/>
                    </IconButton>
                }
            </Grid>
        </Grid>

        <Menu className="user-info"
              id="simple-menu"
              anchorEl={anchorE1}
              open={open}
              onClose={handleRequestClose}
              PaperProps={{
                  style: {
                      minWidth: 120,
                      paddingTop: 0,
                      paddingBottom: 0
                  }
              }}
        >
            <MenuItem onClick={() => setShowProfile(true)}>
                <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
                <IntlMessages id="popup.profile"/>
            </MenuItem>
            <MenuItem onClick={handleRequestClose}>
                <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                <IntlMessages id="popup.setting"/>
            </MenuItem>
            <MenuItem onClick={() => {
                handleRequestClose();
                dispatch(userSignOut());
            }}>
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

                <IntlMessages id="popup.logout"/>
            </MenuItem>
        </Menu>

        <Profile open={showProfile}
                 user={user}
                 onSaveProfile={onSaveProfileFunction}
                 onProfileClose={() => {
                     setShowProfile(false)
                 }}
        />
    </div>
  );
};

export default UserInfo;


