import {
  FETCH_ALL_PROJECT,
  FETCH_ALL_PROJECT_SUCCESS,
  FILTER_PROJECT,
  GET_ALL_PROJECT,
  GET_UNSELECTED_ALL_PROJECT,
  HANDLE_REQUEST_CLOSE,
  HIDE_PROJECT_LOADER,
  ON_ADD_PROJECT,
  ON_ALL_PROJECT_SELECT,
  ON_PROJECT_CLOSE,
  ON_PROJECT_SELECT,
  ON_DELETE_PROJECT,
  ON_DELETE_SELECTED_PROJECT,
  ON_FILTER_OPTION_SELECT,
  ON_SAVE_PROJECT,
  ON_SAVE_PROJECT_SUCCESS,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_PROJECT_SEARCH,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS
} from 'constants/ActionTypes';


export const fetchProjects = () => {
  return {
    type: FETCH_ALL_PROJECT,
    payload: ''
  };
};

export const fetchProjectsSuccess = (project) => {
  return {
    type: FETCH_ALL_PROJECT_SUCCESS,
    payload: project
  }
};
export const showProjectMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const onProjectSelect = (data) => {
  return {
    type: ON_PROJECT_SELECT,
    payload: data
  };
};


export const onAddProject = () => {
  return {
    type: ON_ADD_PROJECT,
    payload: ''
  };
};
export const onProjectClose = () => {
  return {
    type: ON_PROJECT_CLOSE,
    payload: ''
  };
};
export const onFilterOptionSelect = (filter, profile) => {
  return {
    type: ON_FILTER_OPTION_SELECT,
    payload: {filter, profile}
  };
};
export const onSaveProject = (data) => {
  return {
    type: ON_SAVE_PROJECT,
    payload: data
  };
};
export const onSaveProjectSuccess = (data) => {
  return {
    type: ON_SAVE_PROJECT_SUCCESS,
    payload: data
  };
};
export const onDeleteProject = (data) => {
  return {
    type: ON_DELETE_PROJECT,
    payload: data
  };
};
export const onDeleteSelectedProject = (allProject) => {
  return {
    type: ON_DELETE_SELECTED_PROJECT,
    payload: allProject
  };
};
export const filterProject = (userName) => {
  return {
    type: FILTER_PROJECT,
    payload: userName
  };
};
export const getAllProject = () => {
  return {
    type: GET_ALL_PROJECT,
    payload: ''
  };
};
export const getUnselectedAllProject = () => {
  return {
    type: GET_UNSELECTED_ALL_PROJECT,
    payload: ''
  };
};
export const onAllProjectSelect = () => {
  return {
    type: ON_ALL_PROJECT_SELECT,
    payload: ''
  };
};
export const updateProjectSearch = (search) => {
  return {
    type: UPDATE_PROJECT_SEARCH,
    payload: search
  };
};
export const onToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER,
  };
};
export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE,
  };
};
export const hideProjectLoader = () => {
  return {
    type: HIDE_PROJECT_LOADER,
  };
};

export const fetchUsers = () => {
  return {
    type: FETCH_ALL_USERS
  };
};
export const fetchUsersSuccess = (users) => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: users
  }
};