import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';
import {useSelector} from "react-redux";

const Routes = ({match}) => {
    const {profileLoaded, roles, active} = useSelector(({auth}) => auth);
    if(!profileLoaded) return '';

    if(!active)
    return (
        <Switch>
            <Route component={asyncComponent(() => import("./Errors/403"))}/>
        </Switch>);
    if(!roles || !roles.admin)
    return (
    <Switch>
        <Route path={`${match.url}/tasks-assigned`}
               component={asyncComponent(() => import('./Tasks/index.js'))}/>
        <Route path={`${match.url}/tasks-created`}
               component={asyncComponent(() => import('./Tasks/index.js'))}/>
        <Route path={`${match.url}/tasks-watching`}
               component={asyncComponent(() => import('./Tasks/index.js'))}/>
        <Route path={`${match.url}/archive-assigned`}
               component={asyncComponent(() => import('./Tasks/index.js'))}/>
        <Route path={`${match.url}/archive-created`}
               component={asyncComponent(() => import('./Tasks/index.js'))}/>
        <Route path={`${match.url}/archive-watching`}
               component={asyncComponent(() => import('./Tasks/index.js'))}/>
        <Route path={`${match.url}/report-time`}
               component={asyncComponent(() => import('./Reports/time.js'))}/>
        <Route path={`${match.url}/tasks/:projectCode/:taskId`}
               component={asyncComponent(() => import('./Tasks/detail.js'))}/>
        <Route component={asyncComponent(() => import("./Errors/404"))}/>
        <Route component={asyncComponent(() => import("./Errors/403"))}/>
    </Switch>);
    else return (
        <Switch>
            <Route path={`${match.url}/users`}
                   component={asyncComponent(() => import('./Users'))}/>
            <Route path={`${match.url}/services`}
                   component={asyncComponent(() => import('./Services'))}/>
            <Route path={`${match.url}/projects`}
                   component={asyncComponent(() => import('./Projects'))}/>
            <Route path={`${match.url}/sprints`}
                   component={asyncComponent(() => import('./Sprints'))}/>
            <Route path={`${match.url}/tasks-assigned`}
                   component={asyncComponent(() => import('./Tasks/index.js'))}/>
            <Route path={`${match.url}/tasks-created`}
                   component={asyncComponent(() => import('./Tasks/index.js'))}/>
            <Route path={`${match.url}/tasks-watching`}
                   component={asyncComponent(() => import('./Tasks/index.js'))}/>
            <Route path={`${match.url}/archive-assigned`}
                   component={asyncComponent(() => import('./Tasks/index.js'))}/>
            <Route path={`${match.url}/archive-created`}
                   component={asyncComponent(() => import('./Tasks/index.js'))}/>
            <Route path={`${match.url}/archive-watching`}
                   component={asyncComponent(() => import('./Tasks/index.js'))}/>
            <Route path={`${match.url}/tasks/:projectCode/:taskId`}
                   component={asyncComponent(() => import('./Tasks/detail.js'))}/>
            <Route path={`${match.url}/user-sprint/:userId`}
                   component={asyncComponent(() => import('./Sprints/detail.js'))}/>
            <Route path={`${match.url}/report-time`}
                   component={asyncComponent(() => import('./Reports/time.js'))}/>
            <Route path={`${match.url}/project/:projectId`}
                   component={asyncComponent(() => import('./Projects/detail.js'))}/>
            <Route component={asyncComponent(() => import("./Errors/404"))}/>
            <Route component={asyncComponent(() => import("./Errors/403"))}/>
        </Switch>
    );
}


export default withRouter(Routes);

