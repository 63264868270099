import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/firestore';   // for cloud firestore
import 'firebase/functions';   // for cloud functions

// Initialize Firebase
export const testConfig  = {
  apiKey: "AIzaSyA0i7hnz8xe_BsLT8cNkR_WEPt6ui76D8Q",
  authDomain: "testing-1c7c0.firebaseapp.com",
  databaseURL: "https://testing-1c7c0.firebaseio.com",
  projectId: "testing-1c7c0",
  storageBucket: "testing-1c7c0.appspot.com",
  messagingSenderId: "711399426287",
  appId: "1:711399426287:web:4a6d2b9cb5196fc747731a"
};
export const config = {
  apiKey: "AIzaSyBaZdTeD6A_dVDGaeLBfuTZ1Is8xc2lorc",
  authDomain: "edgecrm-767cf.firebaseapp.com",
  databaseURL: "https://edgecrm-767cf.firebaseio.com",
  projectId: "edgecrm-767cf",
  storageBucket: "edgecrm-767cf.appspot.com",
  messagingSenderId: "502011307197",
  appId: "1:502011307197:web:1110b5c52febf22c9403d4",
  measurementId: "G-X743RBQTBL"
};


const app = firebase.initializeApp(config);

const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.firestore();
const firestore = firebase.firestore;
const functions = firebase.functions();
const fileStorage = firebase.storage().ref();

// Initialize your Web app as described in the Get started for Web
// Firebase previously initialized using firebase.initializeApp().
/*if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  functions.useFunctionsEmulator("http://localhost:5001");
  database.settings({
    host: "localhost:8080",
    ssl: false
  });
}*/

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  if (window.location.hostname.indexOf('crm.edgelab.com.ua') === -1) {
    let re = /https?:\/\/[A-Za-z0-9-.:]+\/?/i;
    let newLocation = window.location.href;
    window.location.replace(newLocation.replace(re, "https://crm.edgelab.com.ua/"));
  }
}


export {
  auth,
  database,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  firestore,
  functions,
  fileStorage,
  firebase
};