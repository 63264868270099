// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';


//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const UPDATE_AUTH = 'update_auth';
export const UPDATE_PROFILE = 'update_profile';
export const ON_SAVE_PROFILE = 'on_save_profile';
export const SET_FAVOURITE_PROJECT = 'set_favourite_project';
export const SET_FAVOURITE_SPRINT = 'set_favourite_sprint';
export const FETCH_ALL_USERS = 'fetch_all_users';
export const FETCH_ALL_USERS_SUCCESS = 'fetch_all_users_success';
export const ON_FILTER_ADD = 'on_filter_add';
export const ON_FILTER_ADD_SUCCESS = 'on_filter_add_success';
export const ON_FILTER_REMOVE = 'on_filter_remove';
export const ON_RATE_MANAGER = 'on_rate_manager';
export const ON_RATE_MANAGER_SUCCESS = 'on_rate_manager_success';


//// User Module const
export const GET_ALL_USER = 'get_all_user';
export const GET_UNSELECTED_ALL_USER = 'get_unselected_all_user';
export const HIDE_USER_LOADER = 'hide_user_loader';
export const ON_ALL_USER_SELECT = 'on_all_user_select';
export const ON_USER_CLOSE = 'on_user_close';
export const ON_USER_SELECT = 'on_user_select';
export const UPDATE_USER_SEARCH = 'on_update_user_search';
export const FILTER_USER = 'filter_user';
export const FETCH_ALL_USER_SUCCESS  = 'fetch_all_user_success';
export const FETCH_ALL_USER  = 'fetch_all_user';
export const SET_CLIENT  = 'set_client';
export const SET_DEPARTMENT_MANAGER  = 'set_department_manager';
export const SET_ACTIVE  = 'set_active';
export const DEACTIVATE  = 'deactivate_user';
export const SET_CLIENT_SUCCESS  = 'set_client_success';
export const SET_DEPARTMENT_MANAGER_SUCCESS  = 'set_department_manager_success';
export const SET_ACTIVE_SUCCESS  = 'set_active_success';
export const DEACTIVATE_SUCCESS = 'deactivate_user_success';

//// Project Module const

export const FETCH_ALL_PROJECT_SUCCESS  = 'fetch_all_project_success';
export const FETCH_ALL_PROJECT  = 'fetch_all_project';
export const FILTER_PROJECT = 'filter_project';
export const GET_ALL_PROJECT = 'get_all_project';
export const GET_UNSELECTED_ALL_PROJECT = 'get_unselected_all_project';
export const HIDE_PROJECT_LOADER = 'hide_project_loader';
export const ON_ADD_PROJECT = 'on_add_project';
export const ON_ALL_PROJECT_SELECT = 'on_all_project_select';
export const ON_PROJECT_CLOSE = 'on_project_close';
export const ON_PROJECT_SELECT = 'on_project_select';
export const ON_SAVE_PROJECT = 'on_save_project';
export const ON_SAVE_PROJECT_SUCCESS = 'on_save_project_success';
export const ON_DELETE_PROJECT = 'on_delete_project';
export const ON_DELETE_SELECTED_PROJECT = 'on_delete_selected_project';
export const UPDATE_PROJECT_SEARCH = 'on_update_project_search';



//// Task Module const

export const FETCH_ALLOWED_PROJECTS = 'fetch_allowed_projects';
export const FETCH_ALLOWED_PROJECTS_SUCCESS = 'fetch_allowed_projects_success';
export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TASK = 'fetch_all_task';
export const FETCH_ALL_TASK_SUCCESS = 'fetch_all_task_success';
export const FETCH_ALL_TASK_CONVERSATION = 'fetch_all_task_conversation';
export const FETCH_ALL_TASK_CONVERSATION_SUCCESS = 'fetch_all_task_conversation_success';
export const SELECT_ALL_TASK = 'select_all_task';
export const GET_ALL_TASK = 'get_all_task';
export const GET_UNSELECTED_ALL_TASK = 'get_unselected_all_task';
export const GET_STARRED_TASK = 'get_starred_task';
export const GET_UNSTARRED_TASK = 'get_unstarred_task';
export const GET_IMPORTANT_TASK = 'get_important_task';
export const GET_UNIMPORTANT_TASK = 'get_unimportant_task';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TASK_UPDATE = 'on_task_update';
export const ON_TASK_UPDATE_SUCCESS = 'on_task_update_success';
export const ON_SUBMIT_COMMENT = 'on_submit_comment';
export const ON_SUBMIT_COMMENT_SUCCESS = 'on_submit_comment_success';
export const ON_APPROVE_COMMENT = 'on_approve_comment';
export const ON_APPROVE_COMMENT_SUCCESS = 'on_approve_comment_success';
export const ON_UPDATE_COMMENT = 'on_update_comment';
export const ON_UPDATE_COMMENT_SUCCESS = 'on_update_comment_success';
export const ON_DELETE_TASK = 'on_delete_task';
export const SEARCH_TASK = 'search_task';
export const SHOW_TASKS = 'show_tasks';
export const GET_TASK_CONVERSATION = 'get_task_conversation';
export const ON_TASK_CHECKED = 'on_task_checked';
export const ON_TASK_ADD = 'on_task_add';
export const ON_TASK_ADD_SUCCESS = 'on_task_add_success';
export const ON_TASK_SELECT = 'on_task_select';
export const SET_CURRENT_TASK_NULL = 'set_current_task_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_CUSTOM_FILTERS = 'get_custom_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_MESSAGE = 'hide_message';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const SHOW_MESSAGE = 'show_message';
export const SHOW_401 = 'show_401';
export const FETCH_TASK_DETAIL_SUCCESS = 'fetch_task_detail_success';
export const FETCH_TASK_DETAIL = 'fetch_task_detail';
export const FETCH_ALL_TASK_ASSIGNED = 'fetch_all_task_assigned';
export const FETCH_ALL_TASK_AUTHOR = 'fetch_all_task_author';
export const FETCH_ALL_TASK_WATCHER = 'fetch_all_task_watcher';
export const FETCH_ALL_TASK_ASSIGNED_ARCHIVE = 'fetch_all_task_assigned_archive';
export const FETCH_ALL_TASK_AUTHOR_ARCHIVE = 'fetch_all_task_author_archive';
export const FETCH_ALL_TASK_WATCHER_ARCHIVE = 'fetch_all_task_watcher_archive';
export const FETCH_TASK_LOG = 'fetch_task_log';
export const FETCH_TASK_LOG_SUCCESS = 'fetch_task_log_success';
export const ADD_SPENT_TIME = 'add_spent_time';
export const ADD_SPENT_TIME_SUCCESS = 'add_spent_time_success';
export const FETCH_TASK_TIMELOG = 'fetch_task_timelog';
export const FETCH_TASK_TIMELOG_SUCCESS = 'fetch_task_timelog_success';
export const ON_DELETE_TIMELOG = 'on_delete_timelog';
export const ON_DELETE_TIMELOG_SUCCESS = 'on_delete_timelog_success';
export const FETCH_ALL_TASK_BY_SPRINT_AND_USER = 'fetch_all_task_by_sprint_and_user';
export const FETCH_ALL_TASK_BY_PROJECT = 'fetch_all_task_by_project';

//// Sprint Module const

export const FETCH_ALL_SPRINT_SUCCESS  = 'fetch_all_sprint_success';
export const FETCH_ALL_SPRINT  = 'fetch_all_sprint';
export const FILTER_SPRINT = 'filter_sprint';
export const GET_ALL_SPRINT = 'get_all_sprint';
export const GET_UNSELECTED_ALL_SPRINT = 'get_unselected_all_sprint';
export const HIDE_SPRINT_LOADER = 'hide_sprint_loader';
export const ON_ADD_SPRINT = 'on_add_sprint';
export const ON_ALL_SPRINT_SELECT = 'on_all_sprint_select';
export const ON_SPRINT_CLOSE = 'on_sprint_close';
export const ON_SPRINT_SELECT = 'on_sprint_select';
export const ON_SAVE_SPRINT = 'on_save_sprint';
export const ON_SAVE_SPRINT_SUCCESS = 'on_save_sprint_success';
export const ON_DELETE_SPRINT = 'on_delete_sprint';
export const ON_DELETE_SELECTED_SPRINT = 'on_delete_selected_sprint';
export const UPDATE_SPRINT_SEARCH = 'on_update_sprint_search';
export const FETCH_ACTIVE_SPRINT = 'fetch_active_sprint';
export const FETCH_ACTIVE_SPRINT_SUCCESS = 'fetch_active_sprint_success';
export const ON_SPRINT_MENU_ITEM_SELECT = 'on_sprint_menu_item_select';
export const ON_UPLOAD_FILE = 'on_upload_file';
export const ON_UPLOAD_FILE_SUCCESS = 'on_upload_file_success';
export const ON_DELETE_FILE = 'on_delete_file';
export const ON_DELETE_LINK = 'on_delete_link';
export const ON_ADD_LINK = 'on_add_link';
export const ON_ADD_LINK_SUCCESS = 'on_add_link_success';


//// Reports Module const

export const FETCH_TIMEREPORT_DATA = 'fetch_timereport_data';
export const FETCH_TIMEREPORT_DATA_SUCCESS = 'fetch_timereport_data_success';
export const HIDE_REPORT_LOADER = 'hide_report_loader';


//// Service Module const
export const ON_ADD_SERVICE = 'on_add_service';
export const ON_ADD_SERVICE_SUCCESS = 'on_add_service_success';
export const ON_SAVE_SERVICE = 'on_save_service';
export const ON_SAVE_SERVICE_SUCCESS = 'on_save_service_success';
export const GET_ALL_SERVICE = 'get_all_service';
export const GET_UNSELECTED_ALL_SERVICE = 'get_unselected_all_service';
export const HIDE_SERVICE_LOADER = 'hide_service_loader';
export const ON_ALL_SERVICE_SELECT = 'on_all_service_select';
export const ON_SERVICE_CLOSE = 'on_service_close';
export const ON_SERVICE_SELECT = 'on_service_select';
export const UPDATE_SERVICE_SEARCH = 'on_update_service_search';
export const FILTER_SERVICE = 'filter_service';
export const FETCH_ALL_SERVICE_SUCCESS  = 'fetch_all_service_success';
export const FETCH_ALL_SERVICE  = 'fetch_all_service';
export const SET_ACTIVE_SERVICE  = 'set_active_service';
export const DEACTIVATE_SERVICE  = 'deactivate_service';
export const SET_ACTIVE_SERVICE_SUCCESS  = 'set_active_success';
export const DEACTIVATE_SERVICE_SUCCESS = 'deactivate_service_success';
export const ON_DELETE_SERVICE = 'on_delete_service';
export const ON_DELETE_SELECTED_SERVICE = 'on_delete_selected_service';






