import {all, call, fork, put, takeEvery, select, takeLeading} from "redux-saga/effects";
import {
    FETCH_ALL_USER,
    SET_CLIENT,
    SET_DEPARTMENT_MANAGER,
    SET_ACTIVE,
    DEACTIVATE
} from "constants/ActionTypes";
import {
    deactivateSuccess,
    fetchUsersSuccess, setActiveSuccess, setClientSuccess, setDepartmentManagerSuccess,
    showUserMessage,
} from "actions/User";
import {
    database,  
    firestore,
    functions
} from "../firebase/firebase";

const fetchUsersRequest = async () =>
    await  database.collection("users").get().then(
        (querySnapshot) => {
            const users = [];
            querySnapshot.forEach((querySnapshot) => {
                if(querySnapshot.id !== 'index')
                    users.push({id: querySnapshot.id, ...querySnapshot.data()});
            });
            return users;
        }
    ).catch(error => error);

const setClientRequest = async (uid) =>{
    const  setClient = functions.httpsCallable('setClientRole');
    const result = await setClient({uid: uid}).then((result) => {
        return uid;
    }).catch(error => error);

    if(result.message){
        return result;
    }

    const dbResult = await  database.collection("users").doc(uid).update({
        client: true
    }).then(() => {
        console.log('User updated.');
        return uid;
    }).catch(error => error);

    if(dbResult.message){
        return result;
    }

    return uid;
}

const setDepartmentManagerRequest = async (uid) =>{
    const  setDeparmentManager = functions.httpsCallable('setDepartmentManagerRole');
    const result = await setDeparmentManager({uid: uid}).then((result) => {
        return uid;
    }).catch(error => error);

    if(result.message){
        return result;
    }

    const dbResult = await  database.collection("users").doc(uid).update({
        departmentManager: true
    }).then(() => {
        console.log('User updated.');
        return uid;
    }).catch(error => error);

    if(dbResult.message){
        return result;
    }

    return uid;
}

const setActiveRequest = async (uid) =>{
    const  setActive = functions.httpsCallable('setActiveRole');
    const result = await setActive({uid: uid}).then(async (result) => {
        return uid;
    }).catch(error => error);

    if(result.message){
        return result;
    }

    const dbResult = await  database.collection("users").doc(uid).update({
        active: true
    }).then(() => {
        console.log('User updated.');
        return uid;
    }).catch(error => error);

    if(dbResult.message){
        return result;
    }

    return uid;
}

const deactivateRolesRequest = async (uid) =>{
    const  deactivateRoles = functions.httpsCallable('deactivateRoles');
    const result = await deactivateRoles({uid: uid}).then(async (result) => {
        return uid;
    }).catch(error => error);

    if(result.message){
        return result;
    }

    const dbResult = await  database.collection("users").doc(uid).update({
        active: false,
        departmentManager: false,
        client: false
    }).then(() => {
        console.log('User updated.');
        return uid;
    }).catch(error => error);

    if(dbResult.message){
        return result;
    }

    return uid;
}


function* fetchUsersWorker() {
    try {
        const data = yield call(fetchUsersRequest);
        yield put(fetchUsersSuccess(data));
    } catch (error) {
        yield put(showUserMessage(error));
    }
}

function* setClientWorker({payload}) {
    try {
        const data = yield call(setClientRequest, payload);
        if(data.message){
            yield put(showUserMessage(data.message));
        }else {
            yield put(setClientSuccess(data));
            window.location.reload();
        }
    } catch (error) {
        yield put(showUserMessage(error));
    }
}

function* setDepartmentManagerWorker({payload}) {
    try {
        const data = yield call(setDepartmentManagerRequest, payload);
        if(data.message){
            yield put(showUserMessage(data.message));
        }else
            yield put(setDepartmentManagerSuccess(data));

    } catch (error) {
        yield put(showUserMessage(error));
    }
}

function* setActiveWorker({payload}) {
    try {
        const data = yield call(setActiveRequest, payload);
        if(data.message){
            yield put(showUserMessage(data.message));
        }else
            yield put(setActiveSuccess(data));
    } catch (error) {
        yield put(showUserMessage(error));
    }
}

function* deactivateWorker({payload}) {
    try {
        const data = yield call(deactivateRolesRequest, payload);
        if(data.message){
            yield put(showUserMessage(data.message));
        }else
            yield put(deactivateSuccess(data));
    } catch (error) {
        yield put(showUserMessage(error));
    }
}


export function* fetchAllUserWatcher() {
    yield takeEvery(FETCH_ALL_USER, fetchUsersWorker);
}

export function* setClientWatcher() {
    yield takeEvery(SET_CLIENT, setClientWorker);
}

export function* setDepartmentManagerWatcher() {
    yield takeEvery(SET_DEPARTMENT_MANAGER, setDepartmentManagerWorker);
}

export function* setActiveWatcher() {
    yield takeEvery(SET_ACTIVE, setActiveWorker);
}

export function* deactivateWatcher() {
    yield takeEvery(DEACTIVATE, deactivateWorker);
}

export default function* rootSaga() {
    yield all([
        fork(fetchAllUserWatcher),
        fork(setClientWatcher),
        fork(setDepartmentManagerWatcher),
        fork(setActiveWatcher),
        fork(deactivateWatcher),
        ]);
}