import {
  FETCH_ALL_TASK,
  FETCH_ALL_TASK_CONVERSATION,
  FETCH_ALL_TASK_CONVERSATION_SUCCESS,
  FETCH_ALL_TASK_SUCCESS,
  GET_ALL_TASK,
  GET_IMPORTANT_TASK,
  GET_NAV_FILTERS,
  GET_NAV_LABELS,
  GET_STARRED_TASK,
  GET_UNIMPORTANT_TASK,
  GET_UNSELECTED_ALL_TASK,
  GET_UNSTARRED_TASK,
  HANDLE_REQUEST_CLOSE,
  ON_HIDE_LOADER,
  ON_LABEL_SELECT,
  ON_LABEL_UPDATE,
  ON_OPTION_MENU_SELECT,
  ON_SORTEND,
  ON_TASK_ADD,
  ON_TASK_ADD_SUCCESS,
  ON_TASK_CHECKED,
  ON_TASK_SELECT,
  ON_TASK_UPDATE,
  ON_TASK_UPDATE_SUCCESS,
  ON_TOGGLE_DRAWER,
  SEARCH_TASK,
  SELECT_ALL_TASK,
  SET_CURRENT_TASK_NULL,
  SHOW_MESSAGE,
  SHOW_401,
  UPDATE_SEARCH,
  FETCH_ALLOWED_PROJECTS,
  FETCH_ALLOWED_PROJECTS_SUCCESS,
  FETCH_TASK_DETAIL,
  FETCH_TASK_DETAIL_SUCCESS,
  FETCH_ALL_TASK_ASSIGNED,
  FETCH_ALL_TASK_AUTHOR,
  FETCH_ALL_TASK_WATCHER,
  FETCH_ALL_TASK_ASSIGNED_ARCHIVE,
  FETCH_ALL_TASK_AUTHOR_ARCHIVE,
  FETCH_ALL_TASK_WATCHER_ARCHIVE,
  FETCH_TASK_LOG,
  FETCH_TASK_LOG_SUCCESS,
  ADD_SPENT_TIME,
  ADD_SPENT_TIME_SUCCESS,
  FETCH_TASK_TIMELOG,
  FETCH_TASK_TIMELOG_SUCCESS,
  ON_DELETE_TIMELOG,
  ON_DELETE_TIMELOG_SUCCESS,
  FETCH_ALL_TASK_BY_SPRINT_AND_USER,
  FETCH_ALL_TASK_BY_PROJECT,
  ON_UPLOAD_FILE,
  ON_DELETE_FILE,
  ON_ADD_LINK,
  ON_ADD_LINK_SUCCESS,
  ON_DELETE_LINK,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  GET_CUSTOM_FILTERS,
  ON_UPLOAD_FILE_SUCCESS,
  ON_SUBMIT_COMMENT,
  ON_SUBMIT_COMMENT_SUCCESS,
  ON_UPDATE_COMMENT,
  ON_UPDATE_COMMENT_SUCCESS,
  ON_APPROVE_COMMENT,
  ON_APPROVE_COMMENT_SUCCESS
} from 'constants/ActionTypes'


export const fetchTask = () => {
  return {
    type: FETCH_ALL_TASK
  };
};
export const fetchTaskAuthor = (author = null) => {
  return {
    type: FETCH_ALL_TASK_AUTHOR,
    payload: author
  };
};
export const fetchTaskAssigned = (user = null) => {
  return {
    type: FETCH_ALL_TASK_ASSIGNED,
    payload: user
  };
};
export const fetchTaskWatcher= () => {
  return {
    type: FETCH_ALL_TASK_WATCHER
  };
};
export const fetchTaskAuthorArchive = () => {
  return {
    type: FETCH_ALL_TASK_AUTHOR_ARCHIVE
  };
};
export const fetchTaskAssignedArchive = () => {
  return {
    type: FETCH_ALL_TASK_ASSIGNED_ARCHIVE
  };
};
export const fetchTaskWatcherArchive= () => {
  return {
    type: FETCH_ALL_TASK_WATCHER_ARCHIVE
  };
};
export const fetchTasksBySprintAndUser = (sprint, user) => {
  return {
    type: FETCH_ALL_TASK_BY_SPRINT_AND_USER,
    payload: {sprint, user}
  };
};
export const fetchTasksByProject = (projectCode, profile) => {
  return {
    type: FETCH_ALL_TASK_BY_PROJECT,
    payload: {projectCode, profile}
  };
};
export const fetchTaskDetail = (taskId, profile) => {
  return {
    type: FETCH_TASK_DETAIL,
    payload: {id: taskId, profile}
  };
};
export const fetchTaskLog = (taskId) => {
  return {
    type: FETCH_TASK_LOG,
    payload: taskId
  };
};

export const fetchTaskTimelog = (taskId) => {
  return {
    type: FETCH_TASK_TIMELOG,
    payload: taskId
  };
};

export const fetchTaskDetailSuccess = (task) => {
  return {
    type: FETCH_TASK_DETAIL_SUCCESS,
    payload: task
  };
};
export const fetchTaskLogSuccess = (task) => {
  return {
    type: FETCH_TASK_LOG_SUCCESS,
    payload: task
  };
};

export const fetchTaskTimelogSuccess = (task) => {
  return {
    type: FETCH_TASK_TIMELOG_SUCCESS,
    payload: task
  };
};
export const fetchTaskConversation = () => {
  return {
    type: FETCH_ALL_TASK_CONVERSATION
  };
};

export const fetchTasksSuccess = (mails) => {
  return {
    type: FETCH_ALL_TASK_SUCCESS,
    payload: mails
  }
};
export const fetchTasksConversationSuccess = (mails) => {
  return {
    type: FETCH_ALL_TASK_CONVERSATION_SUCCESS,
    payload: mails
  }
};
export const fetchAllowedProjects = () => {
  return {
    type: FETCH_ALLOWED_PROJECTS
  }
};
export const fetchAllowedProjectsSuccess = (mails) => {
  return {
    type: FETCH_ALLOWED_PROJECTS_SUCCESS,
    payload: mails
  }
};

export const showTaskMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showTask401 = () => {
  return {
    type: SHOW_401,
    payload: ''
  };
};

export const onSortEnd = (data) => {
  return {
    type: ON_SORTEND,
    payload: data
  };
};

export const onOptionMenuSelect = () => {
  return {
    type: ON_OPTION_MENU_SELECT,
  };
};
export const onLabelSelect = () => {
  return {
    type: ON_LABEL_SELECT,
  };
};
export const selectAllTask = () => {
  return {
    type: SELECT_ALL_TASK,
  };
};
export const getAllTask = () => {
  return {
    type: GET_ALL_TASK,
  };
};


export const getUnselectedAllTask = () => {
  return {
    type: GET_UNSELECTED_ALL_TASK,
  };
};

export const getStarredTask = () => {
  return {
    type: GET_STARRED_TASK,
  };
};

export const getUnStarredTask = () => {
  return {
    type: GET_UNSTARRED_TASK,
  };
};
export const getImportantTask = () => {
  return {
    type: GET_IMPORTANT_TASK,
  };
};
export const getUnimportantTask = () => {
  return {
    type: GET_UNIMPORTANT_TASK,
  };
};
export const onLabelUpdate = (data) => {
  return {
    type: ON_LABEL_UPDATE,
    payload: data
  };
};

export const onTaskUpdate = (data) => {
  return {
    type: ON_TASK_UPDATE,
    payload: data
  };
};

export const onTaskUpdateSuccess = (data) => {
  return {
    type: ON_TASK_UPDATE_SUCCESS,
    payload: data
  };
};

export const onSubmitComment = (data) => {
  return {
    type: ON_SUBMIT_COMMENT,
    payload: data
  };
};

export const onSubmitCommentSuccess = (data) => {
  return {
    type: ON_SUBMIT_COMMENT_SUCCESS,
    payload: data
  };
};

export const onUpdateComment = (data) => {
  return {
    type: ON_UPDATE_COMMENT,
    payload: data
  };
};

export const onUpdateCommentSuccess = (data) => {
  return {
    type: ON_UPDATE_COMMENT_SUCCESS,
    payload: data
  };
};

export const onApproveComment = (data) => {
  return {
    type: ON_APPROVE_COMMENT,
    payload: data
  };
};

export const onApproveCommentSuccess = (data) => {
  return {
    type: ON_APPROVE_COMMENT_SUCCESS,
    payload: data
  };
};

export const onDeleteTimelog = (task, log) => {
  return {
    type: ON_DELETE_TIMELOG,
    payload: {task, log}
  };
};

export const onDeleteTimelogSuccess = (task, log) => {
  return {
    type: ON_DELETE_TIMELOG_SUCCESS,
    payload: {task, log}
  };
};

export const getNavFilters = (filter, profile, allSprint) => {
  const sprint = allSprint ? allSprint[0] : {};
  return {
    type: GET_NAV_FILTERS,
    payload: {filter, profile, sprint}
  };
};
export const getCustomFilters = (filter, profile, allSprint) => {
  const sprint = allSprint ? allSprint[0] : {};
  return {
    type: GET_CUSTOM_FILTERS,
    payload: {filter, profile, sprint}
  };
};
export const getNavLabels = (label, profile) => {
  return {
    type: GET_NAV_LABELS,
    payload: {label, profile}
  };
};

export const onSearchTask = (searchText) => {
  return {
    type: SEARCH_TASK,
    payload: searchText
  };
};
export const onTaskChecked = (data) => {
  return {
    type: ON_TASK_CHECKED,
    payload: data
  };
};
export const onTaskAdd = (data, path = null) => {
  return {
    type: ON_TASK_ADD,
    payload: {...data, path}
  };
};
export const onTaskAddSuccess = (data, path = null) => {
  return {
    type: ON_TASK_ADD_SUCCESS,
    payload: {data, path}
  };
};
export const onTaskSelect = (task) => {
  return {
    type: ON_TASK_SELECT,
    payload: task
  };
};
export const setCurrentTaskNull = () => {
  return {
    type: SET_CURRENT_TASK_NULL,
  };
};

export const taskToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER,
  };
};

export const updateSearch = (searchTask) => {
  return {
    type: UPDATE_SEARCH,
    payload: searchTask
  };
};

export const hideTaskLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
export const handleTaskMenuRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE,
  };
};

export const addSpentTime = (task, minutes, comment, profile) => {
  return {
    type: ADD_SPENT_TIME,
    payload: {task, minutes, comment, profile}
  };
};

export const addSpentTimeSuccess = (task, minutes, comment, profile) => {
  return {
    type: ADD_SPENT_TIME_SUCCESS,
    payload: {task, minutes, comment, profile}
  };
};

export const onUploadFile = (task, file, profile) => {
  return {
    type: ON_UPLOAD_FILE,
    payload: {task, file, profile}
  };
};

export const onUploadFileSuccess = (task, file) => {
  return {
    type: ON_UPLOAD_FILE_SUCCESS,
    payload: {task, file}
  };
};

export const onDeleteFile = (task, file) => {
  return {
    type: ON_DELETE_FILE,
    payload: {task, file}
  };
};

export const onDeleteLink = (task, link) => {
  return {
    type: ON_DELETE_LINK,
    payload: {task, link}
  };
};

export const fetchAllUsers = () => {
  return {
    type: FETCH_ALL_USERS
  };
};
export const fetchAllUsersSuccess = (users) => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: users
  }
};

export const onAddLink = (task, links) => {
  return {
    type: ON_ADD_LINK,
    payload: {task, links}
  };
};

export const onAddLinkSuccess = (payload) => {
  return {
    type: ON_ADD_LINK_SUCCESS,
    payload: payload
  };
};