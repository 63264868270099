import React, {useEffect} from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import {useDispatch, useSelector} from "react-redux";
import {fetchUsers} from "actions/Project";

const SideBarContent = () => {
  const {roles} = useSelector(({auth}) => auth);
  const {allUsers} = useSelector(({projects}) => projects);
  const dispatch = useDispatch();
  let navigationMenus = [];

  useEffect(() => {
        if(roles && roles.admin && !allUsers)
          dispatch(fetchUsers());
      },
      [roles]
  );

  if(!roles || !roles.admin)
    navigationMenus = [
    {
      name: 'sidebar.main',
      type: 'section',
      translate: true,
      children: [
        {
          name: 'task.title',
          type: 'collapse',
          icon: 'check-square',
          translate: true,
          children: [
            {
              name: 'task.assignedToMe',
              type: 'item',
              translate: true,
              link: '/app/tasks-assigned',
            },
            {
              name: 'task.createdByMe',
              type: 'item',
              translate: true,
              link: '/app/tasks-created',
            },
            {
              name: 'task.watchingByMe',
              type: 'item',
              translate: true,
              link: '/app/tasks-watching',
            }
            
          ]
          
        },
        {
          name: 'task.archive',
          type: 'collapse',
          icon: 'check-square',
          translate: true,
          children: [
            {
              name: 'task.assignedToMe',
              type: 'item',
              translate: true,
              link: '/app/archive-assigned',
            },
            {
              name: 'task.createdByMe',
              type: 'item',
              translate: true,
              link: '/app/archive-created',
            }/*,
            {
              name: 'task.watchingByMe',
              type: 'item',
              translate: true,
              link: '/app/archive-watching',
            }*/

          ]

        },
        {
          name: 'report.title',
          type: 'collapse',
          icon: 'view-subtitles',
          translate: true,
          children: [
            {
              name: 'report.time',
              type: 'item',
              translate: true,
              link: '/app/report-time',
            },
          ]

        },

      ]
    },
  ];
  else {
    const usersContent = [];
    if(allUsers)
      allUsers.map((user) => {
        if(!user.client) usersContent.push({
          name: user.displayName,
          type: 'item',
          translate: false,
          link: `/app/user-sprint/${user.id}`,
        })
    });
    
    navigationMenus = [
      {
        name: 'sidebar.main',
        type: 'section',
        translate: true,
        children: [
          {
            name: 'task.title',
            type: 'collapse',
            icon: 'check-square',
            translate: true,
            children: [
              {
                name: 'task.assignedToMe',
                type: 'item',
                translate: true,
                link: '/app/tasks-assigned',
              },
              {
                name: 'task.createdByMe',
                type: 'item',
                translate: true,
                link: '/app/tasks-created',
              },
              {
                name: 'task.watchingByMe',
                type: 'item',
                translate: true,
                link: '/app/tasks-watching',
              }

            ]

          },
          {
            name: 'task.archive',
            type: 'collapse',
            icon: 'archive',
            translate: true,
            children: [
              {
                name: 'task.assignedToMe',
                type: 'item',
                translate: true,
                link: '/app/archive-assigned',
              },
              {
                name: 'task.createdByMe',
                type: 'item',
                translate: true,
                link: '/app/archive-created',
              }/*,
              {
                name: 'task.watchingByMe',
                type: 'item',
                translate: true,
                link: '/app/archive-watching',
              }*/

            ]

          },
          {
            name: 'report.title',
            type: 'collapse',
            icon: 'view-subtitles',
            translate: true,
            children: [
              {
                name: 'report.time',
                type: 'item',
                translate: true,
                link: '/app/report-time',
              },
            ]

          },
          {
            name: 'sidebar.appModule.preferences',
            type: 'collapse',
            icon: 'view-web',
            translate: true,
            children: [
              {
                name: 'users.all',
                type: 'item',
                translate: true,
                link: '/app/users',
              },
              {
                name: 'projects.all',
                type: 'item',
                translate: true,
                link: '/app/projects',
              },
              {
                name: 'services.all',
                type: 'item',
                translate: true,
                link: '/app/services',
              }
            ]
          },
          {
            name: 'sprints.title',
            type: 'collapse',
            icon: 'run',
            translate: true,
            children: [
              {
                name: 'sprints.all',
                type: 'item',
                translate: true,
                link: '/app/sprints',
              },
              ...usersContent
            ]
          },


        ]
      },
    ];
  }

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus}/>
    </CustomScrollbars>
  );
};

export default SideBarContent;
