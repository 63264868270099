import {all, call, fork, put, takeEvery, select, takeLeading} from "redux-saga/effects";
import {
    FETCH_ALL_PROJECT,
    ON_SAVE_PROJECT,
    ON_DELETE_PROJECT,
    ON_DELETE_SELECTED_PROJECT,
    FETCH_ALL_USERS
} from "constants/ActionTypes";
import {
    fetchProjectsSuccess, 
    showProjectMessage, 
    onSaveProjectSuccess,
    fetchUsersSuccess,
} from "actions/Project";
import {
    database,  
    firestore
} from "../firebase/firebase";


const fetchAllProjectRequest = async () =>
    await  database.collection("projects").get().then(
      function(querySnapshot) {
        const projects = [];
        querySnapshot.forEach((querySnapshot) => {

            const users = [];
            for (let [key, value] of Object.entries(querySnapshot.data().users)) {
                users.push({id: key, ...value});

            }

            /*const managers = [];
            for (let [key, value] of Object.entries(querySnapshot.data().managers)) {
                managers.push({id: key, ...value});

            }*/

            const clients = [];
            for (let [key, value] of Object.entries(querySnapshot.data().clients ? querySnapshot.data().clients : [])) {
                clients.push({id: key, ...value});

            }

            const services = [];
            if(querySnapshot.data().services)
            for (let [key, value] of Object.entries(querySnapshot.data().services)) {
                services.push({id: key, ...value});

            }

            projects.push({id: querySnapshot.id, ...querySnapshot.data(), users, clients, services});
          
        });
        return projects;
      }
    ).catch(error => error)

const saveProjectRequest = async (
    title, code, status, users, manager, clients, services,
    managerPPC, managerSEO, managerReputation,
    managerProgramming, managerCopywriting, managerContent
) => {
    if(!code.length) return {
        message: 'Project code couldn\'t be empty.',
    }
    const newProjectRef = database.collection("projects").doc();
    const codeRef = database.collection("projects_index_code").doc(code);
    return database.runTransaction((transaction) => {
        return transaction.get(codeRef).then((codeDoc) => {
            if(codeDoc.exists) throw new Error("This pjoject's code already exists.");
            transaction.set(codeRef, {
                projectId: newProjectRef.id,
            });
            transaction.set(newProjectRef, {
                title,
                code,
                status,
                users,
                manager,
                clients,
                services,
                managerPPC, managerSEO, managerReputation,
                managerProgramming, managerCopywriting, managerContent
            });
            return newProjectRef;
        })

    }).then(function(project) {
        console.log("Transaction successfully committed!");
        return project;
    }).catch(function(error) {
        console.log("Transaction failed: ", error);
        return {
            message: error.message,
        }
    });
};

const updateProjectRequest = async (
    id, title, code, status, users, manager, clients, services,
    managerPPC, managerSEO, managerReputation,
    managerProgramming, managerCopywriting, managerContent
    ) =>
    await  database.collection("projects").doc(id).set({
        title,
        code,
        status,
        users,
        manager,
        clients,
        services,
        managerPPC, managerSEO, managerReputation,
        managerProgramming, managerCopywriting, managerContent
    }).then(() => {}).catch(error => {
        console.log("Updating failed: ", error);
        return {
            message: error.message,
        };
    });


const deleteProjectRequest = async (id, code) =>
    await  database.collection("projects").doc(id).delete()
    .then(() => {
        database.collection("projects_index_code").doc(code).delete()
        console.log("Document successfully deleted!");
    })
    .catch(error => error)

const deleteProjectsRequest = async (projects) => {
    const writeBatch = database.batch();
    projects.forEach((project) => {
        writeBatch.delete(database.collection("projects_index_code").doc(project.code));
        writeBatch.delete(database.collection("projects").doc(project.id));
    });
    await writeBatch.commit()
    .then(() => console.log("Documents successfully deleted!"))
    .catch(error => error);

}

const fetchUsersRequest = async () =>
    await  database.collection("users").get().then(
        (querySnapshot) => {
            const users = [];
            querySnapshot.forEach((querySnapshot) => {
                if(querySnapshot.id !== 'index')
                    users.push({id: querySnapshot.id, ...querySnapshot.data()});
            });
            return users;
        }
    ).catch(error => error);

function* fetchAllProjectWorker({}) {
    try {
        const data = yield call(fetchAllProjectRequest);
        yield put(fetchProjectsSuccess(data));
    } catch (error) {
       yield put(showProjectMessage(error));
    }
}

function* saveProjectWorker({payload}) {
    const {title, code} = payload;
    let {users, manager, clients, services, managerPPC, managerSEO, managerReputation, managerProgramming, managerCopywriting, managerContent} = payload;
    let {id} = payload;
    const status = true;
    try {
        if(manager)
            manager = {
                id: manager.id,
                displayName: manager.displayName,
                email: manager.email,
                photoURL: manager.photoURL ? manager.photoURL : '',
                client: manager.client
            };
        if(managerPPC.id)
            managerPPC = {
                id: managerPPC.id,
                displayName: managerPPC.displayName,
                email: managerPPC.email,
                photoURL: managerPPC.photoURL ? managerPPC.photoURL : '',
                client: managerPPC.client
            };
        if(managerSEO.id)
            managerSEO = {
                id: managerSEO.id,
                displayName: managerSEO.displayName,
                email: managerSEO.email,
                photoURL: managerSEO.photoURL ? managerSEO.photoURL : '',
                client: managerSEO.client
            };
        if(managerReputation.id)
            managerReputation = {
                id: managerReputation.id,
                displayName: managerReputation.displayName,
                email: managerReputation.email,
                photoURL: managerReputation.photoURL ? managerReputation.photoURL : '',
                client: managerReputation.client
            };
        if(managerProgramming.id)
            managerProgramming = {
                id: managerProgramming.id,
                displayName: managerProgramming.displayName,
                email: managerProgramming.email,
                photoURL: managerProgramming.photoURL ? managerProgramming.photoURL : '',
                client: managerProgramming.client
            };
        if(managerCopywriting.id)
            managerCopywriting = {
                id: managerCopywriting.id,
                displayName: managerCopywriting.displayName,
                email: managerCopywriting.email,
                photoURL: managerCopywriting.photoURL ? managerCopywriting.photoURL : '',
                client: managerCopywriting.client
            };
        if(managerContent.id)
            managerContent = {
                id: managerContent.id,
                displayName: managerContent.displayName,
                email: managerContent.email,
                photoURL: managerContent.photoURL ? managerContent.photoURL : '',
                client: managerContent.client
            };

        users = users === undefined ? [] : users;
        clients = clients === undefined ? [] : clients;
        services = services === undefined ? [] : services;
        //users = users === undefined ? managers : [...users, ...managers];
        const usersDatabaseFormat = users.reduce((a,b) => {a[b.id]={displayName: b.displayName, email: b.email, photoURL: b.photoURL ? b.photoURL : '', client: b.client ? b.client : false}; return a;}, {});
        const clientsDatabaseFormat = clients.reduce((a,b) => {a[b.id]={displayName: b.displayName, email: b.email, photoURL: b.photoURL ? b.photoURL : '', client: b.client ? b.client : false}; return a;}, {});
        const servicesDatabaseFormat = services.reduce((a,b) => {a[b.id]={title: b.title, type: b.type, description: b.description}; return a;}, {});

        if(id !== undefined){
          console.log('Обновляем запись')

          const result = yield call(updateProjectRequest,
              id, title, code, status, usersDatabaseFormat, manager,
              clientsDatabaseFormat, servicesDatabaseFormat,
              managerPPC, managerSEO, managerReputation,
              managerProgramming, managerCopywriting, managerContent
          );

          if(result && result.message)
              yield put(showProjectMessage(result.message));
          else {

              yield put(onSaveProjectSuccess({
                  id, title, code, status, users, manager, services, clients,
                  managerPPC, managerSEO, managerReputation,
                  managerProgramming, managerCopywriting, managerContent
              }));
          }
        }else{
          console.log('Создаем запись')

          const result = yield call(saveProjectRequest,
              title, code, status, usersDatabaseFormat, manager,
              clientsDatabaseFormat, servicesDatabaseFormat,
              managerPPC, managerSEO, managerReputation,
              managerProgramming, managerCopywriting, managerContent
          );
          if(result.message)
              yield put(showProjectMessage(result.message));
          else {
              yield put(onSaveProjectSuccess({
                  id: result.id, title, code, status, users, manager, clients, services,
                  managerPPC, managerSEO, managerReputation,
                  managerProgramming, managerCopywriting, managerContent
              }));
          }
        }
    } catch (error) {
       yield put(showProjectMessage(error));
    }
}

function* deleteProjectWorker({payload}) {
    const {id, code} = payload;
    try {
        yield call(deleteProjectRequest, id, code);
    } catch (error) {
       yield put(showProjectMessage(error));
    }
}

function* deleteSelectedProjectWorker({payload}) {
    //const state = yield select();
    const projects = payload.map(project => project).filter((project) => project.selected == true);
    
    try {
          yield call(deleteProjectsRequest, projects);
    } catch (error) {
       yield put(showProjectMessage(error));
    }
}

function* fetchUsersWorker() {
    try {
        const data = yield call(fetchUsersRequest);
        yield put(fetchUsersSuccess(data));
    } catch (error) {
        yield put(showProjectMessage(error));
    }
}

export function* fetchAllProjectWatcher() {
    yield takeEvery(FETCH_ALL_PROJECT, fetchAllProjectWorker);
}

export function* saveProjectWatcher() {
    yield takeLeading(ON_SAVE_PROJECT, saveProjectWorker);
}

export function* deleteProjectWatcher() {
    yield takeEvery(ON_DELETE_PROJECT, deleteProjectWorker);
}

export function* deleteSelectedProjectWatcher() {
    yield takeEvery(ON_DELETE_SELECTED_PROJECT, deleteSelectedProjectWorker);
}

export function* fetchUsersWatcher() {
    yield takeEvery(FETCH_ALL_USERS, fetchUsersWorker);
}

export default function* rootSaga() {
    yield all([
        fork(fetchAllProjectWatcher),
        fork(saveProjectWatcher),
        fork(deleteProjectWatcher),
        fork(deleteSelectedProjectWatcher),
        fork(fetchUsersWatcher),
        ]);
}