import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  FIXED_DRAWER,
  MINI_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH
} from "../constants/ActionTypes";
import { DARK_INDIGO } from "../constants/ThemeColors";

const rltLocale = ["ar"];
const initialSettings = {
  navCollapsed: false,
  drawerType: MINI_DRAWER,
  themeColor: DARK_INDIGO,
  darkTheme: false,
  width: 1366,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: localStorage.getItem('languageId') ? localStorage.getItem('languageId') : "russian",
    locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : "ru",
    name: localStorage.getItem('name') ? localStorage.getItem('name') : "Russian",
    icon: localStorage.getItem('icon') ? localStorage.getItem('icon') : "ru"
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case THEME_COLOR:
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color
      };
    case DARK_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme
      };
    case SWITCH_LANGUAGE:
      localStorage.setItem('languageId', action.payload.languageId);
      localStorage.setItem('locale', action.payload.locale);
      localStorage.setItem('name', action.payload.name);
      localStorage.setItem('icon', action.payload.icon);
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };

    default:
      return state;
  }
};

export default settings;
