const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'Eng',
    icon: 'us'
  },
  {
    languageId: 'ukrainian',
    locale: 'ua',
    name: 'Укр',
    icon: 'ua'
  },
  {
    languageId: 'russian',
    locale: 'ru',
    name: 'Рус',
    icon: 'ru'
  }

];
export default languageData;
