import {all, call, fork, put, takeEvery, select, takeLeading} from "redux-saga/effects";
import {
    FETCH_ALL_SERVICE,
    SET_ACTIVE_SERVICE,
    DEACTIVATE_SERVICE,
    ON_SAVE_SERVICE,
    ON_DELETE_SERVICE,
    ON_DELETE_SELECTED_SERVICE
} from "constants/ActionTypes";
import {
    deactivateSuccess,
    fetchServicesSuccess,
    setActiveSuccess,
    showServiceMessage,
    onSaveServiceSuccess
} from "actions/Service";
import {
    database,  
    firestore,
    functions
} from "../firebase/firebase";

const fetchServicesRequest = async () =>
    await  database.collection("services").get().then(
        (querySnapshot) => {
            const services = [];
            querySnapshot.forEach((querySnapshot) => {
                if(querySnapshot.id !== 'index')
                    services.push({id: querySnapshot.id, ...querySnapshot.data()});
            });
            return services;
        }
    ).catch(error => error);


const setActiveRequest = async (uid) =>
    await  database.collection("services").doc(uid).update({
        status: true
    }).then(() => {
        console.log('Service updated.');
        return uid;
    }).catch(error => error);

const deactivateRequest = async (uid) =>
    await  database.collection("services").doc(uid).update({
        status: false
    }).then(() => {
        console.log('Service updated.');
        return uid;
    }).catch(error => error);

const saveServiceRequest = async (title, description, type, status) =>
    await  database.collection("services").add({
        title,
        description,
        status,
        type
    }).then(function(entity) {
        console.log("Document successfully updated!");
        return entity;
    }).catch(function(error) {
        console.log("Operation failed: ", error);
        return {
            message: error.message,
        }
    });

const updateServiceRequest = async (id, title, description, type, status) =>
    await  database.collection("services").doc(id).set({
        title,
        description,
        type,
        status
    }).then(function(entity) {
        console.log("Document successfully created!");
        return entity;
    }).catch(function(error) {
        console.log("Operation failed: ", error);
        return {
            message: error.message,
        }
    });


const deleteServiceRequest = async (id, code) =>
    await  database.collection("services").doc(id).delete()
        .then(() => {
            database.collection("services_index_code").doc(code).delete()
            console.log("Document successfully deleted!");
        })
        .catch(error => error)

const deleteServicesRequest = async (services) => {
    const writeBatch = database.batch();
    services.forEach((service) => {
        writeBatch.delete(database.collection("services_index_code").doc(service.code));
        writeBatch.delete(database.collection("services").doc(service.id));
    });
    await writeBatch.commit()
        .then(() => console.log("Documents successfully deleted!"))
        .catch(error => error);

}


function* fetchServicesWorker() {
    try {
        const data = yield call(fetchServicesRequest);
        yield put(fetchServicesSuccess(data));
    } catch (error) {
        yield put(showServiceMessage(error));
    }
}

function* setActiveWorker({payload}) {
    try {
        const data = yield call(setActiveRequest, payload);
        if(data.message){
            yield put(showServiceMessage(data.message));
        }else
            yield put(setActiveSuccess(data));
    } catch (error) {
        yield put(showServiceMessage(error));
    }
}

function* deactivateWorker({payload}) {
    try {
        const data = yield call(deactivateRequest, payload);
        if(data.message){
            yield put(showServiceMessage(data.message));
        }else
            yield put(deactivateSuccess(data));
    } catch (error) {
        yield put(showServiceMessage(error));
    }
}

function* saveServiceWorker({payload}) {
    const {title, type, description, id, status} = payload;
    try {
        if(id !== undefined){
            console.log('Обновляем запись')

            const result = yield call(updateServiceRequest, id, title, description, type, status);

            if(result && result.message)
                yield put(showServiceMessage(result.message));
            else {

                yield put(onSaveServiceSuccess({id, title, description, type, status}));
            }
        }else{
            console.log('Создаем запись')

            const result = yield call(saveServiceRequest, title, description, type, status);
            if(result.message)
                yield put(showServiceMessage(result.message));
            else {
                yield put(onSaveServiceSuccess({id: result.id, title, description, type, status}));
            }
        }
    } catch (error) {
        yield put(showServiceMessage(error));
    }
}

function* deleteServiceWorker({payload}) {
    const {id, code} = payload;
    try {
        yield call(deleteServiceRequest, id, code);
    } catch (error) {
        yield put(showServiceMessage(error));
    }
}

function* deleteSelectedServiceWorker({payload}) {
    //const state = yield select();
    const services = payload.map(service => service).filter((service) => service.selected == true);

    try {
        yield call(deleteServicesRequest, services);
    } catch (error) {
        yield put(showServiceMessage(error));
    }
}


export function* fetchAllServiceWatcher() {
    yield takeEvery(FETCH_ALL_SERVICE, fetchServicesWorker);
}

export function* setActiveWatcher() {
    yield takeEvery(SET_ACTIVE_SERVICE, setActiveWorker);
}

export function* deactivateWatcher() {
    yield takeEvery(DEACTIVATE_SERVICE, deactivateWorker);
}

export function* saveServiceWatcher() {
    yield takeLeading(ON_SAVE_SERVICE, saveServiceWorker);
}

export function* deleteServiceWatcher() {
    yield takeEvery(ON_DELETE_SERVICE, deleteServiceWorker);
}

export function* deleteSelectedServiceWatcher() {
    yield takeEvery(ON_DELETE_SELECTED_SERVICE, deleteSelectedServiceWorker);
}

export default function* rootSaga() {
    yield all([
        fork(fetchAllServiceWatcher),
        fork(setActiveWatcher),
        fork(deactivateWatcher),
        fork(saveServiceWatcher),
        fork(deleteServiceWatcher),
        fork(deleteSelectedServiceWatcher),
        ]);
}