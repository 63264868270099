import {all, call, fork, put, takeEvery, takeLeading} from 'redux-saga/effects';
import {
    fetchTasksSuccess,
    showTaskMessage,
    showTask401,
    fetchAllowedProjectsSuccess,
    onTaskAddSuccess,
    fetchTaskDetailSuccess,
    fetchTaskLogSuccess,
    fetchTaskTimelogSuccess,
    fetchAllUsersSuccess,
    onTaskUpdateSuccess,
    onSubmitCommentSuccess,
    onUpdateCommentSuccess,
    onApproveCommentSuccess,
    addSpentTimeSuccess,
    onAddLinkSuccess,
    onDeleteTimelogSuccess
} from 'actions/Task';
import {auth, database, fileStorage, firebase, firestore, functions} from '../firebase/firebase';
import {
    FETCH_ALL_TASK,
    FETCH_ALL_TASK_AUTHOR,
    FETCH_ALL_TASK_ASSIGNED,
    FETCH_ALL_TASK_WATCHER,
    FETCH_ALL_TASK_ASSIGNED_ARCHIVE,
    FETCH_ALL_TASK_AUTHOR_ARCHIVE,
    FETCH_ALL_TASK_WATCHER_ARCHIVE,
    FETCH_ALLOWED_PROJECTS,
    ON_TASK_ADD,
    FETCH_TASK_DETAIL,
    ON_TASK_UPDATE,
    FETCH_TASK_LOG,
    ADD_SPENT_TIME,
    FETCH_TASK_TIMELOG,
    FETCH_ALL_TASK_BY_PROJECT,
    FETCH_ALL_TASK_BY_SPRINT_AND_USER,
    ON_DELETE_TIMELOG,
    ON_UPLOAD_FILE,
    ON_DELETE_FILE,
    ON_DELETE_LINK,
    FETCH_ALL_USERS,
    ON_SUBMIT_COMMENT,
    ON_UPDATE_COMMENT,
    ON_ADD_LINK,
    ON_APPROVE_COMMENT
} from 'constants/ActionTypes';
import {onUploadFileSuccess} from "../actions";
import data from "../components/ToDoCard/data";
import { typeToService } from 'servicetypeconverter/service-type-converter';

//
// Requests
//

const getTasks = async () =>
    await database.collection('tasks')
        .where('status', 'in', ['Open', 'Clarification', 'InWork', 'Checking', 'Complete'])
        .get()
        .then((snapshot) => {
            const tasks = [];
            snapshot.forEach((rawData) => {
                const task = rawData.data();
                task.id = rawData.id;
                //task.watchers =  Object.keys(task.watchers).map((keyName, index) => ({id: keyName, displayName: task.watchers[keyName]}));
                task.dueDate =  task.dueDate ? task.dueDate.toDate() : null;
                tasks.push(task);
            });
            return tasks;
        })
        .catch(error => error);

const getTasksByAuthor = async (authorId) =>
    await database.collection('users')
        //.doc(localStorage.getItem('user_id'))
        .doc(authorId && authorId.id !== '4vow0T9vp0WO9OGMbCuQ9tOh5gI3' ? authorId.id : localStorage.getItem('user_id'))
        .collection('cache')
        .doc('author')
        .get()
        .then((doc) => {
            if(doc.exists){
                const rawTasks = doc.data().tasks;
                const tasks = Object.keys(rawTasks).map((keyName, index) => {
                    const dueDate =  rawTasks[keyName].dueDate ? rawTasks[keyName].dueDate.toDate() : null;
                    return {...rawTasks[keyName], id: keyName, dueDate}
                });
                return tasks;
            }else return [];
        })
        .catch(error => error);

const getTasksByAssigned = async (userId) =>
    await database.collection('users')
        .doc(userId && userId.id !== '4vow0T9vp0WO9OGMbCuQ9tOh5gI3' ? userId.id : localStorage.getItem('user_id'))
        .collection('cache')
        .doc('assigned')
        .get()
        .then((doc) => {
            if(doc.exists){
                const rawTasks = doc.data().tasks;
                const tasks = Object.keys(rawTasks).map((keyName, index) => {
                    const dueDate =  rawTasks[keyName].dueDate ? rawTasks[keyName].dueDate.toDate() : null;
                    return {...rawTasks[keyName], id: keyName, dueDate}
                });
                return tasks;
            }else return [];
        })
        .catch(error => error);

const getTasksByWatcher = async () =>
    await database.collection('users')
        .doc(localStorage.getItem('user_id'))
        .collection('cache')
        .doc('watcher')
        .get()
        .then((doc) => {
            if(doc.exists){
                const rawTasks = doc.data().tasks;
                const tasks = Object.keys(rawTasks).map((keyName, index) => {
                    const dueDate =  rawTasks[keyName].dueDate ? rawTasks[keyName].dueDate.toDate() : null;
                    return {...rawTasks[keyName], id: keyName, dueDate}
                });
                return tasks;
            }else return [];
        })
        .catch(error => error);

const getTasksByAuthorArchive = async () =>
    await database.collection('tasks')
        .where('author.id', '==', localStorage.getItem('user_id'))
        .where('createdAt', '>=', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
        .where('status', 'in', ['Closed', 'Cancelled'])
        .get()
        .then((snapshot) => {
            const tasks = [];
            snapshot.forEach((rawData) => {
                const task = rawData.data();
                task.id = rawData.id;
                //task.watchers =  Object.keys(task.watchers).map((keyName, index) => ({id: keyName, displayName: task.watchers[keyName]}));
                task.dueDate =  task.dueDate ? task.dueDate.toDate() : null;
                tasks.push(task);
            });
            return tasks;
        })
        .catch(error => {console.log(error.toString()); return error;});
/*database.collection('users')
        .doc(localStorage.getItem('user_id'))
        .collection('cache')
        .doc('author-archive')
        .get()
        .then((doc) => {
            if(doc.exists){
                const rawTasks = doc.data().tasks;
                const tasks = Object.keys(rawTasks).map((keyName, index) => {
                    const dueDate =  rawTasks[keyName].dueDate ? rawTasks[keyName].dueDate.toDate() : null;
                    return {...rawTasks[keyName], id: keyName, dueDate}
                });
                return tasks;
            }else return [];
        })
        .catch(error => error);*/

const getTasksByAssignedArchive = async () =>
    await database.collection('tasks')
        .where('assigned.id', '==', localStorage.getItem('user_id'))
        .where('createdAt', '>=', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
        .where('status', 'in', ['Closed', 'Cancelled'])
        .get()
        .then((snapshot) => {
            const tasks = [];
            snapshot.forEach((rawData) => {
                const task = rawData.data();
                task.id = rawData.id;
                //task.watchers =  Object.keys(task.watchers).map((keyName, index) => ({id: keyName, displayName: task.watchers[keyName]}));
                task.dueDate =  task.dueDate ? task.dueDate.toDate() : null;
                tasks.push(task);
            });
            return tasks;
        })
        .catch(error => {console.log(error.toString()); return error;});
    /*await database.collection('users')
        .doc(localStorage.getItem('user_id'))
        .collection('cache')
        .doc('assigned-archive')
        .get()
        .then((doc) => {
            if(doc.exists){
                const rawTasks = doc.data().tasks;
                const tasks = Object.keys(rawTasks).map((keyName, index) => {
                    const dueDate =  rawTasks[keyName].dueDate ? rawTasks[keyName].dueDate.toDate() : null;
                    return {...rawTasks[keyName], id: keyName, dueDate}
                });
                return tasks;
            }else return [];
        })
        .catch(error => error);*/

const getTasksByWatcherArchive = async () => [];
    /*await database.collection('tasks')
        .where('status', '==', 'Complete')
        .where(`watchers.${localStorage.getItem('user_id')}.indexed`, '==', true)
        .where('createdAt', '>=', new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
        .orderBy('createdAt', 'desc')
        .limit(100)
        .get()
        .then((snapshot) => {
            const tasks = [];
            snapshot.forEach((rawData) => {
                const task = rawData.data();
                task.id = rawData.id;
                //task.watchers =  Object.keys(task.watchers).map((keyName, index) => ({id: keyName, displayName: task.watchers[keyName]}));
                task.dueDate =  task.dueDate ? task.dueDate.toDate() : null;
                tasks.push(task);
            });
            return tasks;
        })
        .catch(error => {console.log(error.toString()); return error;});*/
    /*await database.collection('users')
        .doc(localStorage.getItem('user_id'))
        .collection('cache')
        .doc('watcher-archive')
        .get()
        .then((doc) => {
            if(doc.exists){
                const rawTasks = doc.data().tasks;
                const tasks = Object.keys(rawTasks).map((keyName, index) => {
                    const dueDate =  rawTasks[keyName].dueDate ? rawTasks[keyName].dueDate.toDate() : null;
                    return {...rawTasks[keyName], id: keyName, dueDate}
                });
                return tasks;
            }else return [];
        })
        .catch(error => error);*/

const getTasksBySprintAndUser = async (sprintId, userId) =>
    await database.collection('sprints').doc(sprintId)
        .collection('performers').doc(userId)
        .get()
        .then((doc) => {
            if(doc.exists){
                const rawTasks = doc.data().tasks;
                const tasks = Object.keys(rawTasks).map((keyName, index) => {
                    const dueDate =  rawTasks[keyName].dueDate ? rawTasks[keyName].dueDate.toDate() : null;
                    return {...rawTasks[keyName], id: keyName, dueDate}
                });
                return tasks;
            }else return [];
        })
        .catch(error => error);

const getTasksByProject = async (projectCode, profile) => {
    if(profile.roles && profile.roles.admin)
        return await database.collection('tasks')
            .where('project.code', '==', projectCode)
            .where('status', 'in', ['Open', 'InWork', 'Clarification', 'Complete', 'Checking'])
            .get()
            .then((snapshot) => {
                const tasks = [];
                snapshot.forEach((rawData) => {
                    const task = rawData.data();
                    task.id = rawData.id;
                    //task.watchers =  Object.keys(task.watchers).map((keyName, index) => ({id: keyName, displayName: task.watchers[keyName]}));
                    task.dueDate =  task.dueDate ? task.dueDate.toDate() : null;
                    tasks.push(task);
                });
                return tasks;
            })
            .catch(error => error);
    else {
        console.log('Нет прав');
        return [];
    }
}

const fixTaskDetail = async (task, fieldName) => {
    console.log(`Поврежденная информация ${fieldName}. Пытаемся восстановить.`);
    return await database.collection('users')
        .where('displayName', '==', task[fieldName].displayName)
        .get()
        .then((usersQuery) => {
            usersQuery.forEach((user) => {
                database.collection('tasks').doc(task.id).update({
                    [`${fieldName}.id`]: user.id,
                    [`${fieldName}.email`]: user.data().email,
                }).then(() => console.log('Восстановлена.'));
                task[fieldName].id = user.id;
                task[fieldName].email = user.data().email;
            });
            return task;
        })
        .catch(error => error);
}

const getTaskDetail = async (taskId) => {
    return await database.collection('tasks').doc(taskId).get()
        .then((doc) => {
            if (doc.exists) {
                const task = [];
                task.id = doc.id;
                //const watchersFormat = Object.keys(doc.data().watchers).map((keyName, index) => ({id: keyName, displayName: doc.data().watchers[keyName]}) );
                task.dueDate = doc.data().dueDate ? doc.data().dueDate.toDate() : null;
                return {...doc.data(), ...task};
            } else
                return {
                    message: 'Task not found.',
                };
        })
        .catch(function(error) {
            return {
                message: error,
            }
        });
}

const getTasksByIds = async (taskIds) => {
    const string = taskIds.map((task) => task.id);
    return await database.collection('tasks').where(firestore.FieldPath.documentId(), 'in', string).get()
        .then((querySnapshot) => {
            const tasks = [];
            querySnapshot.forEach((rawData) => {
                const task = rawData.data();
                task.id = rawData.id;
                //task.watchers =  Object.keys(task.watchers).map((keyName, index) => ({id: keyName, displayName: task.watchers[keyName]}));
                task.dueDate = task.dueDate ? task.dueDate.toDate() : null;
                tasks.push(task);
            });
            return tasks;
        })
        .catch(function(error) {
            console.log("Transaction failed: ", error);
            return {
                message: error,
            }
        });
}


const getTaskLog = async (taskId) =>
    await database.collection('tasks')
        .doc(taskId)
        .collection('data')
        .doc('update-log').get()
        .then((doc) => {
            if (doc.exists) {

                let dataArray = doc.data().log;
                dataArray = dataArray.map((log) => {
                    const createdAt = log.createdAt ? log.createdAt.toDate() : null;
                    return {...log, createdAt};
                });
                return dataArray;
            }else return [];
        })
        .catch(error => error);


const fetchAllowedProjectsRequest = async () =>
    await  database.collection("projects")
        .where(`users.${localStorage.getItem('user_id')}.displayName`, '>', '')
        .get().then(
            (querySnapshot) => {
                const projects = [];
                querySnapshot.forEach((docSnapshot) => {
                    projects.push({id: docSnapshot.id, ...docSnapshot.data()});
                });
                return projects;
            }
        ).catch(error => error)



const saveTaskRequest = async (project, subject, description, priority, type, status, author, assigned, watchers, conversation, dueDate, links, official) =>
    await database.collection("tasks").add({
        project,
        subject,
        description,
        priority,
        type,
        status,
        author,
        assigned,
        watchers,
        conversation,
        links,
        official,
        answered: !author.client,
        createdAt: firestore.FieldValue.serverTimestamp(),
        revision: 1
    }).catch(function (error) {
        console.log("Task creation failed: ", error);
        return {
            message: error.message,
        }
    });


const updateTaskRequest = async (id, previousValue, newValue, profile, updateOfficial = false) => {


    const taskRef = database.collection("tasks").doc(id);

    return await database.runTransaction(function(transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(taskRef).then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }

            let watcherEqual = false;
            if(Object.keys(sfDoc.data().watchers).length === Object.keys(previousValue.watchers).length){
                if(Object.keys(sfDoc.data().watchers).filter((watcherId) => previousValue.watchers[watcherId].displayName > '').length === Object.keys(sfDoc.data().watchers).length)
                    watcherEqual = true;
            }

            if(
                previousValue.subject !== sfDoc.data().subject ||
                previousValue.description !== sfDoc.data().description ||
                previousValue.priority !== sfDoc.data().priority ||
                (previousValue.dueDate ? previousValue.dueDate.getTime() : null) !== (sfDoc.data().dueDate ? sfDoc.data().dueDate.toDate().getTime() : null) ||
                //JSON.stringify(previousValue.conversation) !== JSON.stringify(sfDoc.data().conversation) ||
                //JSON.stringify(previousValue.files) !== JSON.stringify(sfDoc.data().files) ||
                //JSON.stringify(previousValue.links) !== JSON.stringify(sfDoc.data().links) ||
                !watcherEqual ||
                previousValue.status !== sfDoc.data().status ||
                previousValue.assigned.id !== sfDoc.data().assigned.id ||
                previousValue.author.id !== sfDoc.data().author.id
            ){
                console.log('Данная страница устарела!');


                if(previousValue.subject !== sfDoc.data().subject) console.log('Тема');
                if(previousValue.description !== sfDoc.data().description) console.log('Описание');
                if(previousValue.priority !== sfDoc.data().priority) console.log('Приоритет');
                if((previousValue.dueDate ? previousValue.dueDate.getTime() : null) !== (sfDoc.data().dueDate ? sfDoc.data().dueDate.toDate().getTime() : null)) console.log('Срок сдачи');
                //if(JSON.stringify(previousValue.conversation) !== JSON.stringify(sfDoc.data().conversation)) console.log('Комментарии');
                //if(JSON.stringify(previousValue.files) !== JSON.stringify(sfDoc.data().files)) console.log('Файлы');
                //if(JSON.stringify(previousValue.links) !== JSON.stringify(sfDoc.data().links)) console.log('Связи');
                if(!watcherEqual) console.log('Наблюдатели');
                if(previousValue.status !== sfDoc.data().status) console.log('Статус');
                if(previousValue.assigned.id !== sfDoc.data().assigned.id) console.log('Исполнитель');
                if(previousValue.author.id !== sfDoc.data().author.id) console.log('Автор');


                return Promise.reject( "Данная страница устарела! Обновите страницу, потом попробуйте снова.");
            }

            const author = newValue.author ? newValue.author : sfDoc.data().author;
            const assigned = newValue.assigned ? newValue.assigned : sfDoc.data().assigned;

            let foundClient = false;
            if(newValue.watchers) {
                Object.keys(newValue.watchers).map((watcherId) => {
                    if (newValue.watchers[watcherId].client)
                        foundClient = true;
                });
            }
            if(
                updateOfficial
                || author.client
                || assigned.client
            ){
                foundClient = true;
            }
            transaction.update(taskRef, {
                    ...newValue,
                    official: foundClient,
                    updatedAt: firestore.FieldValue.serverTimestamp()
                }
            );
            console.log('Обновляем саму задачу');

            //Проверка нужно ли обновлять кеш задач пользователей

            const newTaskData = {
                id: id,
                author: {
                    id: author.id,
                    photoURL: author.photoURL ? author.photoURL : '',
                    displayName: author.displayName
                },
                assigned: assigned.id ? {
                    id: assigned.id,
                    photoURL: assigned.photoURL ? assigned.photoURL : '',
                    displayName: assigned.displayName
                } : {},
                priority: newValue.priority ? newValue.priority : sfDoc.data().priority,
                subject: newValue.subject ? newValue.subject : sfDoc.data().subject,
                description: newValue.description ? newValue.description : sfDoc.data().description,
                project: {
                    id: sfDoc.data().project.id,
                    title: sfDoc.data().project.title,
                    code: sfDoc.data().project.code,
                },
                dueDate: newValue.dueDate ? newValue.dueDate : (sfDoc.data().dueDate ? sfDoc.data().dueDate : null),
                status: newValue.status ? newValue.status : sfDoc.data().status,
                type: sfDoc.data().type,
                createdAt: sfDoc.data().createdAt ? sfDoc.data().createdAt : null,
                cached: firestore.Timestamp.now()
            };

            //в этом случае просто апдейтим кеш
            if(
                newValue.subject || newValue.description || newValue.priority || newValue.dueDate || newValue.status
                || newValue.author || newValue.assigned
            ) {

                if(newValue.author){
                    //удаляем кеш старого автора, если изменился автор
                    console.log('удаляем кеш старого автора, если изменился автор');
                    const oldAuthorCache = database.collection('users')
                        .doc(sfDoc.data().author.id)
                        .collection('cache')
                        .doc('author');

                    transaction.update(oldAuthorCache, {
                        [`tasks.${id}`]: firestore.FieldValue.delete()
                    });
                }

                if(newValue.assigned){
                    //удаляем кеш старого исполнителя, если изменился исполнитель
                    console.log('удаляем кеш старого исполнителя, если изменился исполнитель');
                    if(sfDoc.data().assigned.id) {
                        const oldAssignedCache = database.collection('users')
                            .doc(sfDoc.data().assigned.id)
                            .collection('cache')
                            .doc('assigned');

                        transaction.update(oldAssignedCache, {
                            [`tasks.${id}`]: firestore.FieldValue.delete()
                        });
                    }
                }

                let moveToArchive = false;

                if(newValue.status){
                    //обновляем статистику исполнителя
                    if(!profile.client && assigned && profile.authUser === assigned.id){
                        if(newValue.status === 'InWork' || newValue.status === 'Clarification' || newValue.status === 'Complete') {
                            const now = new Date();
                            const assignedStat = database.collection('statistics')
                                .doc(`assigned-statuses-${assigned.id}-${now.getMonth()}${now.getFullYear()}`);

                            transaction.set(assignedStat, {
                                [`${newValue.status}`]: firestore.FieldValue.arrayUnion(id)
                            }, {merge: true});
                        }
                    }

                    //обновляем статистику менеджера, если он исполнитель и автором является клиент
                    if(author.client && assigned && sfDoc.data().project.manager && sfDoc.data().project.manager.id === assigned.id){
                        if(newValue.status === 'InWork' || newValue.status === 'Clarification' || newValue.status === 'Complete') {
                            const now = new Date();
                            const assignedStat = database.collection('statistics-onupdate')
                                .doc(`manager-reply-${assigned.id}`)

                            transaction.update(assignedStat, {
                                [`InWork.${sfDoc.data().project.code}.${id}.closeDate`]: now
                            });
                        }
                        if(newValue.status === 'Open') {
                            const now = new Date();
                            const assignedStat = database.collection('statistics-onupdate')
                                .doc(`manager-reply-${assigned.id}`)

                            transaction.update(assignedStat, {
                                [`InWork.${sfDoc.data().project.code}.${id}`]: {
                                    openDate: now
                                }
                            });
                        }
                    }



                    //если обновился статус, то нужно обновлять обычный либо архивный кеш пользователей
                    if(newValue.status !== 'Closed' && newValue.status !== 'Cancelled'){
                        //если прошлый статус был завершенный, а новый не завершенный, то удаляем архивный кеш

                        /*if(sfDoc.data().status === 'Closed' || sfDoc.data().status === 'Cancelled'){
                            console.log('если прошлый статус был завершенный, а новый не завершенный, то удаляем архивный кеш');

                            const authorArchiveCache = database.collection('users')
                                .doc(author.id)
                                .collection('cache')
                                .doc('author-archive');

                            transaction.update(authorArchiveCache, {
                                [`tasks.${id}`]: firestore.FieldValue.delete()
                            });

                            if(assigned.id) {
                                const assignedArchiveCache = database.collection('users')
                                    .doc(assigned.id)
                                    .collection('cache')
                                    .doc('assigned-archive');

                                transaction.update(assignedArchiveCache, {
                                    [`tasks.${id}`]: firestore.FieldValue.delete()
                                });
                            }

                            if(Object.keys(sfDoc.data().watchers).length){
                                Object.keys(sfDoc.data().watchers).map((keyName, index) => {
                                    const watcherArchiveCache = database.collection('users')
                                        .doc(keyName)
                                        .collection('cache')
                                        .doc('watcher-archive');

                                    transaction.update(watcherArchiveCache, {
                                        [`tasks.${id}`]: firestore.FieldValue.delete()
                                    });
                                });
                            }
                        }*/
                    }else{
                        //переносим задачи в архив
                        //удаляем с активного кеша
                        console.log('переносим задачу в архив. удаляем с активного кеша');
                        const authorCache = database.collection('users')
                            .doc(author.id)
                            .collection('cache')
                            .doc('author');

                        transaction.update(authorCache, {
                            [`tasks.${id}`]: firestore.FieldValue.delete()
                        });

                        if(assigned.id) {
                            const assignedCache = database.collection('users')
                                .doc(assigned.id)
                                .collection('cache')
                                .doc('assigned');

                            transaction.update(assignedCache, {
                                [`tasks.${id}`]: firestore.FieldValue.delete()
                            });
                        }

                        if(Object.keys(sfDoc.data().watchers).length){
                            Object.keys(sfDoc.data().watchers).map((keyName, index) => {
                                const watcherCache = database.collection('users')
                                    .doc(keyName)
                                    .collection('cache')
                                    .doc('watcher');

                                transaction.update(watcherCache, {
                                    [`tasks.${id}`]: firestore.FieldValue.delete()
                                });
                            });
                        }

                        //добавляем в архивный кеш
                        /*console.log('переносим задачу в архив. добавляем в архивный кеш');
                        const authorArchiveCache = database.collection('users')
                            .doc(author.id)
                            .collection('cache')
                            .doc('author-archive');
                        const assignedArchiveCache = database.collection('users')
                            .doc(assigned.id)
                            .collection('cache')
                            .doc('assigned-archive');

                        if(sfDoc.data().author.id){
                            transaction.update(authorArchiveCache, {
                                [`tasks.${id}`]: newTaskData
                            });
                        }
                        if(sfDoc.data().assigned && sfDoc.data().assigned.id){
                            transaction.update(assignedArchiveCache, {
                                [`tasks.${id}`]: newTaskData
                            });
                        }

                        if(Object.keys(sfDoc.data().watchers).length){
                            Object.keys(sfDoc.data().watchers).map((keyName, index) => {
                                const watcherArchiveCache = database.collection('users')
                                    .doc(keyName)
                                    .collection('cache')
                                    .doc('watcher-archive');

                                transaction.update(watcherArchiveCache, {
                                    [`tasks.${id}`]: newTaskData
                                });
                            });
                        }*/

                        //сохраняем флаг, чтобы не обновлять активный кеш далее
                        moveToArchive = true;
                    }

                }


                if(!moveToArchive) {
                    //обновляем кеш автора, исполнителя и наблюдателей
                    console.log('обновляем кеш автора, исполнителя и наблюдателей');
                    const authorCache = database.collection('users')
                        .doc(author.id)
                        .collection('cache')
                        .doc('author');

                    transaction.update(authorCache, {
                        [`tasks.${id}`]: newTaskData
                    });
                    if (assigned && assigned.id) {
                        const assignedCache = database.collection('users')
                            .doc(assigned.id)
                            .collection('cache')
                            .doc('assigned');
                        transaction.update(assignedCache, {
                            [`tasks.${id}`]: newTaskData
                        });
                    }

                    if (Object.keys(sfDoc.data().watchers).length) {
                        Object.keys(sfDoc.data().watchers).map((keyName, index) => {
                            console.log('Обновляем кеш наблюдателя');
                            const watcherCache = database.collection('users')
                                .doc(keyName)
                                .collection('cache')
                                .doc('watcher');

                            transaction.update(watcherCache, {
                                [`tasks.${id}`]: newTaskData
                            });
                        });
                    }
                }
            }

            if(newValue.watchers) {
                console.log('изменился список наблюдателей');
                let removedWatchers = [];
                removedWatchers = Object.keys(sfDoc.data().watchers).filter((keyName) => !(newValue.watchers[keyName] > ''));
                if (removedWatchers.length) {
                    console.log('Есть удаленные наблюдатели. Чистим их кеш.');
                    removedWatchers.map((watcherId) => {
                        const watcherCache = database.collection('users')
                            .doc(watcherId)
                            .collection('cache')
                            .doc('watcher');

                        transaction.update(watcherCache, {
                            [`tasks.${id}`]: firestore.FieldValue.delete()
                        });
                    });
                }

                Object.keys(newValue.watchers).map((watcherId) => {
                    const watcherCache = database.collection('users')
                        .doc(watcherId)
                        .collection('cache')
                        .doc('watcher');

                    transaction.update(watcherCache, {
                        [`tasks.${id}`]: newTaskData
                    });
                });
            }


        });
    }).then(function() {
        console.log("Transaction successfully committed!");
        return true;
    }).catch(function(error) {
        console.log("Transaction failed: ", error);
        return {
            message: error,
        }
    });
};

const addSpentTimeRequest = async (task, spentTime, minutes, comment, profile) => {

    const log = {
        user: {
            id: profile.authUser,
            displayName: profile.displayName,
            email: profile.email
        },
        minutes: parseInt(minutes),
        comment: comment,
        createdAt: firestore.Timestamp.now()
    }

    let today = new Date();
    let month = today.getMonth() + 1; //months from 1-12
    month = month.toString();
    if (month.length < 2)
        month = '0' + month;
    let day = today.getDate();
    if (day.length < 2)
        day = '0' + day;
    let year = today.getFullYear();
    year = year.toString();

    log.task = {
        id: task.id,
        title: task.subject,
        type: task.type,
        project: {
            id: task.project.id,
            code: task.project.code,
            title: task.project.title,
        }
    };


    const sfDocRef = database
        .collection('tasks')
        .doc(task.id);

    const timeLogRef = database.collection('tasks').doc(task.id)
        .collection('data')
        .doc('time-log');

    const timeReportRef = database.collection('timelogs')
        .doc(year.concat(month, '-', profile.authUser));


    const batch = database.batch();

    batch.set(timeLogRef, {
            log: firestore.FieldValue.arrayUnion(log)
        }, {merge: true});

    batch.set(timeReportRef, {
        log: firestore.FieldValue.arrayUnion(log),
        user: profile.authUser,
        email: profile.email,
        month: month,
        year: year
    }, {merge: true});

    batch.update(sfDocRef, {
        spentTime: firestore.FieldValue.increment(parseInt(minutes))
    });

    return batch.commit().then(function () {
        console.log("Task successfully updated!");
        return true;
    }).catch(function (error) {
        console.log("Task update failed: ", error);
        return {
            message: error.message,
        }
    });

    /*const  addSpentTime = functions.httpsCallable('addSpentTime');
    addSpentTime({task, spentTime, minutes, comment, profile}).then((result) => {
        console.log(result);
    });*/
};

const getTaskTimelog = async (taskId) =>
    await database.collection('tasks')
        .doc(taskId)
        .collection('data')
        .doc('time-log').get()
        .then((doc) => {
            if (doc.exists) {

                let dataArray = doc.data().log;
                dataArray = dataArray.map((log) => {
                    const createdAt = log.createdAt ? log.createdAt.toDate() : null;
                    return {...log, createdAt};
                });
                return dataArray;
            }else return [];
        })
        .catch(error => error);

const deleteTimelogRequest = async (task, log) => {
    const sfDocRef = database
        .collection('tasks')
        .doc(task.id);

    const timeLogRef = database.collection('tasks').doc(task.id)
        .collection('data')
        .doc('time-log');

    let today = log.createdAt;
    let month = today.getMonth() + 1; //months from 1-12
    month = month.toString();
    if (month.length < 2)
        month = '0' + month;
    let day = today.getDate();
    if (day.length < 2)
        day = '0' + day;
    let year = today.getFullYear();
    year = year.toString();


    const timeReportRef = database.collection('timelogs')
        .doc(year.concat(month, '-', log.user.id));


    const batch = database.batch();

    batch.set(timeLogRef, {
        log: firestore.FieldValue.arrayRemove(log)
    }, {merge: true});

    batch.set(timeReportRef, {
        log: firestore.FieldValue.arrayRemove(log),
    }, {merge: true});

    batch.update(sfDocRef, {
        spentTime: firestore.FieldValue.increment(-parseInt(log.minutes))
    });

    return batch.commit().then(function () {
        console.log("Task successfully updated!");
        return true;
    }).catch(function (error) {
        console.log("Task update failed: ", error);
        return {
            message: error.message,
        }
    });
};

const uploadFileRequest = async (task, file, profile) => {

    if(file.length > 0) {
        const filesData = [];
        for (const f of file) {
            // Create a Storage Ref w/ username
            const storageRef = fileStorage.child('tasks/' + task.id + '/' + f.name);

            // Upload file
            const uploadTask = storageRef.put(f);

            // Register three observers:
            // 1. 'state_changed' observer, called any time the state changes
            // 2. Error observer, called on failure
            // 3. Completion observer, called on successful completion
            /*uploadTask.on('state_changed', function(snapshot){
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function(error) {
                // Handle unsuccessful uploads
            }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                return uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    //console.log('File available at', downloadURL);
                    return downloadURL;
                });
            });*/

            const fileData = await uploadTask.then((snapshot) => {
                //console.log('File uploaded at', snapshot.ref.getDownloadURL());
                return {
                    name: f.name,
                    ref: 'tasks/' + task.id + '/' + f.name,
                    type: f.type,
                    uploadedBy: {
                        uid: profile.authUser,
                        displayName: profile.displayName
                    },
                    uploadedAt: firestore.Timestamp.now()
                };
            });

            fileData.URL = await storageRef.getDownloadURL().then((url) => url.toString());
            filesData.push(fileData);

        }
        await database.collection("tasks").doc(task.id).update({
                files: firestore.FieldValue.arrayUnion(...filesData)
            }
        ).catch(function (error) {
            console.log("Task update failed: ", error);
            return {
                message: error.message,
            }
        });

        return filesData;
    }else return {
        error: 'File not found.'
    };
};

const deleteFileRequest = async (task, file) => {

    if(file) {
        fileStorage.child(file.ref).delete();

        database.collection("tasks").doc(task.id).update({
                files: firestore.FieldValue.arrayRemove(file)
            }
        ).then(function(task) {
            console.log("Task successfully updated!");
            return task;
        }).catch(function(error) {
            console.log("Task update failed: ", error);
            return {
                message: error.message,
            }
        });

        return true;
    }else return {
        error: 'File not found.'
    };
};

const deleteLinkRequest = async (task, link) => {

    if(link) {
        database.collection("tasks").doc(task.id).update({
                links: firestore.FieldValue.arrayRemove(link)
            }
        ).then(function(task) {
            console.log("Task successfully updated!");
            return task;
        }).catch(function(error) {
            console.log("Task update failed: ", error);
            return {
                message: error.message,
            }
        });

        database.collection("tasks").doc(link.id).update({
                links: firestore.FieldValue.arrayRemove({id: task.id, subject: task.subject, project: task.project})
            }
        ).then(function(task) {
            console.log("Task successfully updated!");
            return task;
        }).catch(function(error) {
            console.log("Task update failed: ", error);
            return {
                message: error.message,
            }
        });

        return true;
    }else return {
        error: 'Link not found.'
    };
};

const fetchUsersRequest = async () =>
    await  database.collection("users").get().then(
        (querySnapshot) => {
            const users = [];
            querySnapshot.forEach((querySnapshot) => {
                if(querySnapshot.id !== 'index')
                    users.push({id: querySnapshot.id, ...querySnapshot.data()});
            });
            return users;
        }
    ).catch(error => error);


const submitCommentRequest = async (id, task, comment, profile) => {

    const taskRef = database.collection("tasks").doc(id);
    let conversationCounter = 0;

    return database.runTransaction(function(transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(taskRef).then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }

            const task = sfDoc.data();
            conversationCounter = sfDoc.data().conversationCounter ? sfDoc.data().conversationCounter : 0;

            if(profile.client){
                transaction.update(taskRef, {
                    conversation: firestore.FieldValue.arrayUnion({
                        commentId: conversationCounter + 1,
                        ...comment
                    }),
                    conversationCounter: firestore.FieldValue.increment(1),
                    answered: false
                });
            } else {
                transaction.update(taskRef, {
                    conversation: firestore.FieldValue.arrayUnion({
                        commentId: conversationCounter + 1,
                        ...comment
                    }),
                    conversationCounter: firestore.FieldValue.increment(1)
                });
            }


            //если менеджер автор данной задачи и клиент исполнитель
            if(task.assigned && task.assigned.client && task.project.manager && task.project.manager.id === task.author.id){
                const statsRef = database.collection('statistics-onupdate')
                    .doc(`manager-reply-${task.author.id}`);

                //если комментарий оставил клиент, то открываем счетчик
                if (profile.client){
                    transaction.update(statsRef, `Comments.${task.project.code}.${id}`,{
                        openDate: comment.createdAt
                    });
                    //закрываем счетчик в другом случае
                } else {
                    transaction.update(statsRef, `Comments.${task.project.code}.${id}.closeDate`,
                        comment.createdAt
                    );
                }
                //если менеджер исполнитель данной задачи и клиент автор
            }else if(task.author.client && task.project.manager && task.assigned && task.project.manager.id === task.assigned.id){

                const statsRef = database.collection('statistics-onupdate')
                    .doc(`manager-reply-${task.assigned.id}`);


                //если комментарий оставил клиент, то открываем счетчик
                if (profile.client){
                    transaction.update(statsRef, `Comments.${task.project.code}.${id}`,{
                        openDate: comment.createdAt
                    });
                    //закрываем счетчик в другом случае
                } else {
                    transaction.update(statsRef, `Comments.${task.project.code}.${id}.closeDate`,
                        comment.createdAt
                    );
                }
            }

        });
    }).then(function() {
        console.log("Transaction successfully committed!");
        return conversationCounter + 1;
    }).catch(function(error) {
        console.log("Transaction failed: ", error);
        return {
            message: error.message,
        }
    });
}

const updateCommentRequest = async (id, comment, newText) => {

    const taskRef = database.collection("tasks").doc(id);
    let updatedConversation = [];

    return database.runTransaction(function(transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(taskRef).then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }

            updatedConversation = sfDoc.data().conversation;

            if(!newText || /^\s*$/.test(newText)){
                if(comment.commentId){
                    updatedConversation = updatedConversation.filter((currentComment) => {
                        if (currentComment.commentId !== comment.commentId) return true;

                        return false;
                    });
                }else { //удалить эту часть после закрытия всех старых задач со старым форматом комментариев
                    updatedConversation = updatedConversation.filter((currentComment) => {
                        if (
                            currentComment.commentId !== comment.commentId
                            || currentComment.uid !== comment.uid
                            || currentComment.sentAt !== comment.sentAt
                            || currentComment.message !== comment.message
                        ) return true;

                        return false;
                    });
                }
            }else {
                if(comment.commentId){
                    updatedConversation = updatedConversation.map((currentComment) => {
                        if (currentComment.commentId !== comment.commentId) return currentComment;

                        currentComment.message = newText;
                        return currentComment;
                    });
                }else {//удалить эту часть после закрытия всех старых задач со старым форматом комментариев
                    updatedConversation = updatedConversation.map((currentComment) => {
                        if (
                            currentComment.commentId !== comment.commentId
                            || currentComment.uid !== comment.uid
                            || currentComment.sentAt !== comment.sentAt
                            || currentComment.message !== comment.message
                        ) return currentComment;

                        currentComment.message = newText;
                        return currentComment;
                    });
                }
            }

            transaction.update(taskRef, {conversation: updatedConversation});
        });
    }).then(function() {
        console.log("Transaction successfully committed!");
        return updatedConversation;
    }).catch(function(error) {
        console.log("Transaction failed: ", error);
        return {
            message: error.message,
        }
    });
}

const approveCommentRequest = async (id, comment) => {

    const taskRef = database.collection("tasks").doc(id);
    let updatedConversation = [];

    return database.runTransaction(function(transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(taskRef).then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Document does not exist!";
            }

            updatedConversation = sfDoc.data().conversation;

            if(comment.commentId){
                updatedConversation = updatedConversation.map((currentComment) => {
                    if (currentComment.commentId !== comment.commentId) return currentComment;

                    currentComment.approved = true;
                    return currentComment;
                });
            }else {//удалить эту часть после закрытия всех старых задач со старым форматом комментариев
                updatedConversation = updatedConversation.map((currentComment) => {
                    if (
                        currentComment.commentId !== comment.commentId
                        || currentComment.uid !== comment.uid
                        || currentComment.sentAt !== comment.sentAt
                        || currentComment.message !== comment.message
                    ) return currentComment;

                    currentComment.approved = true;
                    return currentComment;
                });
            }

            transaction.update(taskRef, {
                conversation: updatedConversation,
                answered: true
            });
        });
    }).then(function() {
        console.log("Transaction successfully committed!");
        return updatedConversation;
    }).catch(function(error) {
        console.log("Transaction failed: ", error);
        return {
            message: error.message,
        }
    });
}

const addLinkRequest = async (task, links) => {

    const currentTaskRef = database.collection("tasks").doc(task.id);

    return await Promise.all(links.map((externalTask) => {
        const externalTaskRef = database.collection("tasks").doc(externalTask.id);
        return externalTaskRef.get().then(function(sfDoc) {
            if (!sfDoc.exists) {
                throw "Неверная ссылка на задачу!";
            }
            let externalData = {
                id: sfDoc.id,
                subject: sfDoc.data().subject,
                project: sfDoc.data().project,
                updatedBy: localStorage.getItem('user_id')
            };
            let batch = database.batch();
            batch.set(currentTaskRef, {
                links: firestore.FieldValue.arrayUnion(externalData)
            }, {merge: true});
            batch.update(externalTaskRef, {links: firestore.FieldValue.arrayUnion({id: task.id, subject: task.subject, project: task.project, updatedBy: localStorage.getItem('user_id')})});
            batch.commit().then(function () {
                console.log("Task successfully updated!");
                return true;
            }).catch(function (error) {
                console.log("Task update failed: ", error);
                return {
                    message: error.message,
                }
            });
            externalData = {
                id: sfDoc.id,
                ...sfDoc.data()
            };
            return externalData;
        });
    }));


}

//
// Workers
//


function* fetchTaskWorker({query}, {payload}) {
    try {
        let fetchedTask = [];
        switch (query) {
            case  "/app/tasks-created":
                fetchedTask = yield call(getTasksByAuthor, payload);
                break;
            case "/app/tasks-assigned":
                fetchedTask = yield call(getTasksByAssigned, payload);
                break;
            case  "/app/tasks-watching":
                fetchedTask = yield call(getTasksByWatcher);
                break;
            case  "/app/archive-created":
                fetchedTask = yield call(getTasksByAuthorArchive);
                break;
            case "/app/archive-assigned":
                fetchedTask = yield call(getTasksByAssignedArchive);
                break;
            case  "/app/archive-watching":
                fetchedTask = yield call(getTasksByWatcherArchive);
                break;
            case  "/app/sprint":
                //fetchedTask = yield call(getTasksBySprintAndUser, payload.sprint, payload.user);
                //if(fetchedTask.length) fetchedTask = yield call(getTasksByIds, fetchedTask);
                if(payload.sprint.tasks)
                Object.keys(payload.sprint.tasks).filter((taskId) => {
                    const task = payload.sprint.tasks[taskId];
                    if(task.assigned && payload.user === task.assigned.id){
                        fetchedTask.push(task);
                        return true;
                    }
                    return false;
                });
                break;
            case  "/app/project":
                fetchedTask = yield call(getTasksByProject, payload.projectCode, payload.profile);
                break;
            case "all":
                fetchedTask = yield call(getTasks);
                break;

        }
        const tasksSnapshot = {tasks: fetchedTask, updateTimestamp: Date.now(), updateType: query};
        yield put(fetchTasksSuccess(tasksSnapshot));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* fetchTaskDetailWorker(action) {
    const taskId = action.payload.id;
    const profile = action.payload.profile;
    try {
        let fetchedTask = yield call(getTaskDetail, taskId);
        //(profile.roles.admin || (task.project.manager && task.project.manager.id === profile.authUser) || (task.assigned && task.assigned.id === profile.authUser) || (task.author.id === profile.authUser))
        if(fetchedTask && !profile.client && fetchedTask.links && fetchedTask.links.length && (profile.roles.admin || (fetchedTask.project.manager && fetchedTask.project.manager.id === profile.authUser))){
            if(fetchedTask.links.length <= 10){
                const linksData = yield call(getTasksByIds, fetchedTask.links);
                if(!linksData.message)
                    fetchedTask.links = linksData;
            }
        }

        //временный код для нормализации базы данных
        /*if(!fetchedTask.author.id || !fetchedTask.author.email){
            fetchedTask = yield call(fixTaskDetail, fetchedTask, 'author');
        }
        if((!fetchedTask.assigned.id || !fetchedTask.assigned.email) && fetchedTask.assigned.displayName){
            fetchedTask = yield call(fixTaskDetail, fetchedTask, 'assigned');
        }*/

        if(fetchedTask.message)
            yield put(showTask401());
        else
            yield put(fetchTaskDetailSuccess(fetchedTask));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* fetchTaskLogWorker(action) {
    const taskId = action.payload;
    try {
        const fetchedLog = yield call(getTaskLog, taskId);
        yield put(fetchTaskLogSuccess(fetchedLog));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* fetchProjectsWorker() {
    try {
        const data = yield call(fetchAllowedProjectsRequest);
        yield put(fetchAllowedProjectsSuccess(data));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* addLinkToTask(externalTask, currentTask) {
    externalTask.links.push({id: currentTask.id, subject: currentTask.subject, project: currentTask.project});
    yield fork(updateTaskRequest, externalTask, externalTask.id, externalTask.project, externalTask.subject, externalTask.description, externalTask.priority, externalTask.type, externalTask.status, externalTask.author, externalTask.assigned, externalTask.watchers, externalTask.conversation, externalTask.dueDate, externalTask.links, externalTask.revision);
    return {id: currentTask.id, subject: currentTask.subject, project: currentTask.project};
}


function* saveTaskWorker({payload}) {
    const { subject, description, priority, type, status, conversation, dueDate, path, official } = payload;
    let {author, assigned, watchers, project} = payload;

    try {
        //project = {id: project.id, code: project.code, title: project.title};
        watchers = watchers === undefined ? {} : watchers;

        const watchersDatabaseFormat = {};
        for (let [key, value] of Object.entries(watchers)) {
            watchersDatabaseFormat[value.id] = {
                ...value
            };
        }

        console.log('Создаем запись')
        const result = yield call(saveTaskRequest, project, subject, description, priority, type, status, author, assigned, watchersDatabaseFormat, conversation, dueDate, [], official);
        if(result.message)
            yield put(showTaskMessage(result.message));
        else {
            yield put(onTaskAddSuccess({id: result.id, project, subject, description, priority, type, status, author, assigned, watchers: {}, conversation: [], dueDate: null, links: [], revision: 1, official}, path));
        }


    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* updateTaskWorker({payload}) {
    const { id, previousValue, newValue, profile } = payload;

    try {

        let updateOfficial = false;
        if(newValue.watchers) {
            //project = {id: project.id, code: project.code, title: project.title};
            const watchers = newValue.watchers === undefined ? {} : newValue.watchers;

            const watchersDatabaseFormat = {};
            for (let [key, value] of Object.entries(watchers)) {
                if(value.client) updateOfficial = true;
                watchersDatabaseFormat[value.id] = {
                    ...value
                };
            }

            newValue.watchers = watchersDatabaseFormat;
        }

        console.log('Обновляем запись');

        if(newValue.dueDate) {
            newValue.dueDate.setHours(5);
            newValue.dueDate.setMinutes(1);
        }

        const result = yield call(updateTaskRequest, id, previousValue, newValue, profile, updateOfficial);

        if (result && result.message)
            yield put(showTaskMessage(result.message));
        else {
            yield put(onTaskUpdateSuccess(payload));
        }



    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* addSpentTimeWorker({payload}) {
    const { task, minutes, profile, comment } = payload;

    try {
        console.log('Отправляем запрос на добавление времени к задаче.')




        const result = yield call(addSpentTimeRequest, task, task.spentTime ? task.spentTime : 0, minutes, comment, profile);
        if(result && result.message)
            yield put(showTaskMessage(result.message));
        else {
            yield put(addSpentTimeSuccess(task, minutes, comment, profile));
        }
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* fetchTaskTimelogWorker(action) {
    const taskId = action.payload;
    try {
        const fetchedLog = yield call(getTaskTimelog, taskId);
        yield put(fetchTaskTimelogSuccess(fetchedLog));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* deleteTimelogWorker(action) {
    const {task, log} = action.payload;
    try {
        yield call(deleteTimelogRequest, task, log);
        yield put(onDeleteTimelogSuccess(task, log));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* uploadFileWorker({payload}) {
    const {file, task, profile} = payload;
    try {

        const uploaded = yield call(uploadFileRequest, task, file, profile);
        console.log(uploaded);
        if(!uploaded.error)
            yield put(onUploadFileSuccess(task, uploaded));
        else
            yield put(showTaskMessage(uploaded.error));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* submitCommentWorker({payload}) {
    const {comment, id, task, profile} = payload;
    try {

        const result = yield call(submitCommentRequest, id, task, comment, profile);

        if(!result.message)
            yield put(onSubmitCommentSuccess({
                commentId: result,
                ...comment
            }));
        else
            yield put(showTaskMessage(result.message));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* updateCommentWorker({payload}) {
    const {id, comment, newText} = payload;
    try {

        const result = yield call(updateCommentRequest, id, comment, newText);

        if(!result.message)
            yield put(onUpdateCommentSuccess(result));
        else
            yield put(showTaskMessage(result.message));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* approveCommentWorker({payload}) {
    const {comment, id, task, profile} = payload;
    try {

        //(task.project[`manager${typeToService(task.type)}`] && task.project[`manager${typeToService(task.type)}`].id === profile.authUser)
        /*if(!profile.roles.admin && task.project.manager.id !== profile.authUser && task.project[`manager${typeToService(task.type)}`].id !== profile.authUser){
            yield put(showTaskMessage('You have no rights.'));
        }*/
        const result = yield call(approveCommentRequest, id, comment);

        if(!result.message)
            yield put(onApproveCommentSuccess(result));
        else
            yield put(showTaskMessage(result.message));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* deleteFileWorker({payload}) {
    const {file, task} = payload;
    try {

        const result = yield call(deleteFileRequest, task, file);
        if(result.error)
            yield put(showTaskMessage(result.error));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* addLinkWorker({payload}) {
    const {task, links} = payload;
    try {
        const result = yield call(addLinkRequest, task, links);

        if(!result.message)
            yield put(onAddLinkSuccess(result));
        else
            yield put(showTaskMessage(result.message));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* deleteLinkWorker({payload}) {
    const {link, task} = payload;
    try {

        const result = yield call(deleteLinkRequest, task, link);
        if(result.error)
            yield put(showTaskMessage(result.error));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* fetchUsersWorker() {
    try {
        const data = yield call(fetchUsersRequest);
        yield put(fetchAllUsersSuccess(data));
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

//
//Watchers
//
export function* fetchTasks() {
    yield takeEvery(FETCH_ALL_TASK, fetchTaskWorker, {query: 'all'});
}
export function* fetchTasksByAuthor() {
    yield takeEvery(FETCH_ALL_TASK_AUTHOR, fetchTaskWorker, {query:  "/app/tasks-created"});
}
export function* fetchTasksByAssigned() {
    yield takeEvery(FETCH_ALL_TASK_ASSIGNED, fetchTaskWorker, {query: "/app/tasks-assigned"});
}
export function* fetchTasksByWatcher() {
    yield takeEvery(FETCH_ALL_TASK_WATCHER, fetchTaskWorker, {query:  "/app/tasks-watching"});
}
export function* fetchTasksByAuthorArchive() {
    yield takeEvery(FETCH_ALL_TASK_AUTHOR_ARCHIVE, fetchTaskWorker, {query:  "/app/archive-created"});
}
export function* fetchTasksByAssignedArchive() {
    yield takeEvery(FETCH_ALL_TASK_ASSIGNED_ARCHIVE, fetchTaskWorker, {query: "/app/archive-assigned"});
}
export function* fetchTasksByWatcherArchive() {
    yield takeEvery(FETCH_ALL_TASK_WATCHER_ARCHIVE, fetchTaskWorker, {query:  "/app/archive-watching"});
}
export function* fetchTasksBySprintAndUser() {
    yield takeEvery(FETCH_ALL_TASK_BY_SPRINT_AND_USER, fetchTaskWorker, {query:  "/app/sprint"});
}
export function* fetchTasksByProject() {
    yield takeEvery(FETCH_ALL_TASK_BY_PROJECT, fetchTaskWorker, {query:  "/app/project"});
}

export function* fetchTaskDetail() {
    yield takeEvery(FETCH_TASK_DETAIL, fetchTaskDetailWorker);
}

export function* fetchTaskLog() {
    yield takeEvery(FETCH_TASK_LOG, fetchTaskLogWorker);
}

export function* fetchAllowedProjectsWatcher() {
    yield takeEvery(FETCH_ALLOWED_PROJECTS, fetchProjectsWorker);
}

export function* saveTaskWatcher() {
    yield takeLeading(ON_TASK_ADD, saveTaskWorker);
}

export function* updateTaskWatcher() {
    yield takeLeading(ON_TASK_UPDATE, updateTaskWorker);
}

export function* addSpentTimeWatcher() {
    yield takeEvery(ADD_SPENT_TIME, addSpentTimeWorker);
}

export function* fetchTaskTimelog() {
    yield takeEvery(FETCH_TASK_TIMELOG, fetchTaskTimelogWorker);
}

export function* deleteTimelog() {
    yield takeEvery(ON_DELETE_TIMELOG, deleteTimelogWorker);
}

export function* onUploadFileWatcher() {
    yield takeEvery(ON_UPLOAD_FILE, uploadFileWorker);
}

export function* onDeleteFileWatcher() {
    yield takeEvery(ON_DELETE_FILE, deleteFileWorker);
}

export function* onAddLinkWatcher() {
    yield takeEvery(ON_ADD_LINK, addLinkWorker);
}

export function* onDeleteLinkWatcher() {
    yield takeEvery(ON_DELETE_LINK, deleteLinkWorker);
}

export function* fetchAllUsersWatcher() {
    yield takeEvery(FETCH_ALL_USERS, fetchUsersWorker);
}

export function* onSubmitCommentWatcher() {
    yield takeEvery(ON_SUBMIT_COMMENT, submitCommentWorker);
}
export function* onUpdateCommentWatcher() {
    yield takeEvery(ON_UPDATE_COMMENT, updateCommentWorker);
}
export function* onApproveCommentWatcher() {
    yield takeEvery(ON_APPROVE_COMMENT, approveCommentWorker);
}

export default function* rootSaga() {
    yield all([
        fork(fetchTasks),
        fork(fetchTasksByAuthor),
        fork(fetchTasksByAssigned),
        fork(fetchTasksByWatcher),
        fork(fetchTasksByAuthorArchive),
        fork(fetchTasksByAssignedArchive),
        fork(fetchTasksByWatcherArchive),
        fork(fetchTasksBySprintAndUser),
        fork(fetchTasksByProject),
        fork(fetchTaskDetail),
        fork(fetchTaskLog),
        fork(fetchAllowedProjectsWatcher),
        fork(saveTaskWatcher),
        fork(updateTaskWatcher),
        fork(addSpentTimeWatcher),
        fork(fetchTaskTimelog),
        fork(deleteTimelog),
        fork(onUploadFileWatcher),
        fork(onDeleteFileWatcher),
        fork(onAddLinkWatcher),
        fork(onDeleteLinkWatcher),
        fork(fetchAllUsersWatcher),
        fork(onSubmitCommentWatcher),
        fork(onUpdateCommentWatcher),
        fork(onApproveCommentWatcher),
    ]);
}