import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import {
    FETCH_TIMEREPORT_DATA,
    FETCH_ALL_USERS
} from "constants/ActionTypes";
import {
    database,  
    firestore
} from "../firebase/firebase";
import {fetchTimereportDataSuccess, showReportMessage, fetchUsersSuccess} from "actions/Report";
import {months} from "moment";

const fetchTimereportDataRequest = async (profile, beginDate, endDate) => {

    if(beginDate >= endDate) return {message: 'Beginning date must be greater than ending date.'}

    let reports = [];

    let current = new Date(beginDate);
    while (current.getMonth() <= endDate.getMonth() && current.getFullYear() <= endDate.getFullYear()){
        let beginMonth = current.getMonth() + 1; //months from 1-12
        beginMonth = beginMonth.toString();
        if (beginMonth.length < 2)
            beginMonth = '0' + beginMonth;
        let beginYear = current.getFullYear();
        beginYear = beginYear.toString();
        await database.collection("timelogs")
            .doc(beginYear.concat(beginMonth, '-', profile))
            .get().then(
                function (documentSnapshot) {
                    if(documentSnapshot.exists){
                        reports = [...reports, ...documentSnapshot.data().log];
                        return true;
                    }
                    return false;
                }
            ).catch(error => error)
        current.setMonth(current.getMonth() + 1);
    }
    return reports.filter((row) => row.createdAt.toDate() <= endDate && row.createdAt.toDate() >= beginDate);
}

const fetchAllUsersTimereportDataRequest = async (allUsers, beginDate, endDate) => {

    if(beginDate >= endDate) return {message: 'Beginning date must be greater than ending date.'}

    let reports = [];

    let current = new Date(beginDate);
    while (current.getMonth() <= endDate.getMonth() && current.getFullYear() <= endDate.getFullYear()){
        let beginMonth = current.getMonth() + 1; //months from 1-12
        beginMonth = beginMonth.toString();
        if (beginMonth.length < 2)
            beginMonth = '0' + beginMonth;
        let beginYear = current.getFullYear();
        beginYear = beginYear.toString();

        await database.collection("timelogs")
            .where('month', '==', beginMonth)
            .where('year', '==', beginYear)
            .get().then(
                function (querySnapshot) {
                    querySnapshot.forEach((querySnapshot) => {
                        reports = [...reports, ...querySnapshot.data().log];
                    });
                    /*if(documentSnapshot.exists){
                        reports = [...reports, ...documentSnapshot.data().log];
                        return true;
                    }*/
                    return true;
                }
            ).catch(error => error)

        current.setMonth(current.getMonth() + 1);
    }
    return reports.filter((row) => row.createdAt.toDate() <= endDate && row.createdAt.toDate() >= beginDate);
}

const fetchUsersRequest = async () =>
    await  database.collection("users").get().then(
        (querySnapshot) => {
            const users = [];
            querySnapshot.forEach((querySnapshot) => {
                if(querySnapshot.id !== 'index')
                    users.push({id: querySnapshot.id, ...querySnapshot.data()});
            });
            return users;
        }
    ).catch(error => error)


function* fetchTimereportDataWorker({payload}) {
    const {profile, beginDate, endDate} = payload;
    try {

        beginDate.setHours(0);
        beginDate.setMinutes(0);
        endDate.setSeconds(0);
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        let data = [];
        if(profile.id === 'all'){
            data = yield call(fetchAllUsersTimereportDataRequest, profile.allUsers, beginDate, endDate);

        }else {
            data = yield call(fetchTimereportDataRequest, profile.id, beginDate, endDate);
        }
        if (data.message) {
            yield put(showReportMessage(data.message));
        }
        yield put(fetchTimereportDataSuccess(data));
    } catch (error) {
        yield put(showReportMessage(error));
    }
}

function* fetchUsersWorker() {
    try {
        const data = yield call(fetchUsersRequest);
        yield put(fetchUsersSuccess(data));
    } catch (error) {
        yield put(showReportMessage(error));
    }
}

export function* fetchTimereportDataWatcher() {
    yield takeEvery(FETCH_TIMEREPORT_DATA, fetchTimereportDataWorker);
}

export function* fetchUsersWatcher() {
    yield takeEvery(FETCH_ALL_USERS, fetchUsersWorker);
}

export default function* rootSaga() {
    yield all([
        fork(fetchTimereportDataWatcher),
        fork(fetchUsersWatcher),
        ]);
}