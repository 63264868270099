import {all, call, fork, put, takeEvery, select, takeLeading} from "redux-saga/effects";
import {
    FETCH_ALL_SPRINT,
    ON_SAVE_SPRINT,
    ON_DELETE_SPRINT,
    ON_DELETE_SELECTED_SPRINT,
    FETCH_ACTIVE_SPRINT,
    ON_SPRINT_MENU_ITEM_SELECT
} from "constants/ActionTypes";
import {
    fetchSprintsSuccess, 
    showSprintMessage, 
    onSaveSprintSuccess,
    fetchActiveSprintSuccess
} from "actions/Sprint";
import {
    database,  
    firestore
} from "../firebase/firebase";


const fetchAllSprintRequest = async () =>
    await  database.collection("sprints").get().then(
      function(querySnapshot) {
        const sprints = [];
        querySnapshot.forEach((docSnapshot) => {
            const startDate =  docSnapshot.data().startDate ? docSnapshot.data().startDate.toDate() : null;
            const dueDate =  docSnapshot.data().dueDate ? docSnapshot.data().dueDate.toDate() : null;
            sprints.push({id: docSnapshot.id, ...docSnapshot.data(), startDate: startDate, dueDate: dueDate});
          
        });
        return sprints;
      }
    ).catch(error => error)

const fetchActiveSprintRequest = async () =>
    await database.collection("sprints")
        .where('startDate', '<=', new Date())
        .orderBy('startDate', 'desc')
        .limit(1)
        .get().then(
            function (querySnapshot) {
                const sprints = [];
                querySnapshot.forEach((docSnapshot) => {
                    const startDate = docSnapshot.data().startDate ? docSnapshot.data().startDate.toDate() : null;
                    const dueDate = docSnapshot.data().dueDate ? docSnapshot.data().dueDate.toDate() : null;
                    sprints.push({id: docSnapshot.id, ...docSnapshot.data(), startDate, dueDate});
                });
                return sprints;
            }
        ).catch(error => error);

const saveSprintRequest = async (title, status, startDate, dueDate) =>
   await database.collection("sprints").add({
       title,
       status,
       startDate,
       dueDate,
       tasks: {}
   }).then(function(sprint) {
        console.log("Document successfully created!");
        return sprint;
    }).catch(function(error) {
        console.log("Operation failed: ", error);
        return {
            message: error.message,
        }
    });


const updateSprintRequest = async (id, title, status, startDate, dueDate) =>
    await  database.collection("sprints").doc(id).update({
        title,
        status,
        startDate,
        dueDate,
    }).then(() => {}).catch(error => {
        console.log("Updating failed: ", error);
        return {
            message: error.message,
        };
    });

const addToSprintRequest = async (id, tasks) => {

    const batch = database.batch();

    tasks.map((task) => {
        if(task.assigned && task.assigned.id)
            batch.update(database.collection("sprints").doc(id), {
            [`tasks.${task.id}`]: {
                id: id,
                author: {
                    id: task.author.id,
                    photoURL: task.author.photoURL ? task.author.photoURL : '',
                    displayName: task.author.displayName
                },
                assigned: task.assigned.id ? {
                    id: task.assigned.id,
                    photoURL: task.assigned.photoURL ? task.assigned.photoURL : '',
                    displayName: task.assigned.displayName
                } : {},
                priority: task.priority,
                subject: task.subject,
                project: {
                    id: task.project.id,
                    title: task.project.title,
                    code: task.project.code,
                },
                dueDate: task.dueDate ? task.dueDate : null,
                status: task.status,
                type: task.type,
                createdAt: task.createdAt ? task.createdAt : null,
                cached: firestore.Timestamp.now()
            }
        });
    });

    return batch.commit().then(function () {
        console.log("Task successfully updated!");
        return true;
    }).catch(function (error) {
        console.log("Task update failed: ", error);
        return {
            message: error.message,
        }
    });
}


const deleteSprintRequest = async (id, code) =>
    await  database.collection("sprints").doc(id).delete()
    .then(() => {
        database.collection("sprints_index_code").doc(code).delete()
        console.log("Document successfully deleted!");
    })
    .catch(error => error)

const deleteSprintsRequest = async (sprints) => {
    const writeBatch = database.batch();
    sprints.forEach((sprint) => {
        writeBatch.delete(database.collection("sprints").doc(sprint.id));
    });
    await writeBatch.commit()
    .then(() => console.log("Documents successfully deleted!"))
    .catch(error => error);

}

function* fetchAllSprintWorker({}) {
    try {
        const data = yield call(fetchAllSprintRequest);
        yield put(fetchSprintsSuccess(data));
    } catch (error) {
       yield put(showSprintMessage(error));
    }
}

function* fetchActiveSprintWorker({}) {
    try {
        const sprint = yield call(fetchActiveSprintRequest);
        yield put(fetchActiveSprintSuccess(sprint));
    } catch (error) {
        yield put(showSprintMessage(error));
    }
}

function* saveSprintWorker({payload}) {
    const {title, startDate, dueDate} = payload;
    let {id} = payload;
    const status = true;
    try {

        startDate.setHours(5);
        startDate.setMinutes(1);
        dueDate.setHours(5);
        dueDate.setMinutes(1);

        if(id !== undefined){
          console.log('Обновляем запись')

          const result = yield call(updateSprintRequest, id, title, status, startDate, dueDate);
          if(result && result.message)
              yield put(showSprintMessage(result.message));
          else {

              yield put(onSaveSprintSuccess({id, title, status, startDate, dueDate}));
          }
        }else{
          console.log('Создаем запись')

          const result = yield call(saveSprintRequest, title, status, startDate, dueDate);
          console.log(result);
          if(result.message)
              yield put(showSprintMessage(result.message));
          else {
              yield put(onSaveSprintSuccess({id: result.id, title, status, startDate, dueDate}));
          }
        }
    } catch (error) {
       yield put(showSprintMessage(error));
    }
}

function* deleteSprintWorker({payload}) {
    const {id} = payload;
    try {
        yield call(deleteSprintRequest, id);
    } catch (error) {
       yield put(showSprintMessage(error));
    }
}

function* deleteSelectedSprintWorker({payload}) {
    //const state = yield select();
    const sprints = payload.map(sprint => sprint).filter((sprint) => sprint.selected === true);
    
    try {
          yield call(deleteSprintsRequest, sprints);
    } catch (error) {
       yield put(showSprintMessage(error));
    }
}

function* addToSprintWorker({payload}) {
    const {sprint, tasks} = payload;
    try {
        yield call(addToSprintRequest, sprint.id, tasks);
    } catch (error) {
        yield put(showSprintMessage(error));
    }
}

export function* fetchAllSprintWatcher() {
    yield takeEvery(FETCH_ALL_SPRINT, fetchAllSprintWorker);
}

export function* fetchActiveSprintWatcher() {
    yield takeEvery(FETCH_ACTIVE_SPRINT, fetchActiveSprintWorker);
}

export function* saveSprintWatcher() {
    yield takeLeading(ON_SAVE_SPRINT, saveSprintWorker);
}

export function* deleteSprintWatcher() {
    yield takeEvery(ON_DELETE_SPRINT, deleteSprintWorker);
}

export function* deleteSelectedSprintWatcher() {
    yield takeEvery(ON_DELETE_SELECTED_SPRINT, deleteSelectedSprintWorker);
}

export function* addToSprintWatcher() {
    yield takeEvery(ON_SPRINT_MENU_ITEM_SELECT, addToSprintWorker);
}

export default function* rootSaga() {
    yield all([
        fork(fetchAllSprintWatcher),
        fork(fetchActiveSprintWatcher),
        fork(saveSprintWatcher),
        fork(deleteSprintWatcher),
        fork(deleteSelectedSprintWatcher),
        fork(addToSprintWatcher),
        ]);
}