import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import projectSagas from './Project';
import taskSagas from './Task';
import sprintSagas from './Sprint';
import reportSagas from './Report';
import userSagas from './User';
import serviceSagas from './Service';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        projectSagas(),
        taskSagas(),
        sprintSagas(),
        reportSagas(),
        userSagas(),
        serviceSagas()
    ]);
}
