import {
  FETCH_TIMEREPORT_DATA,
  FETCH_TIMEREPORT_DATA_SUCCESS,
  SHOW_MESSAGE,
  HIDE_REPORT_LOADER,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS
} from 'constants/ActionTypes';

export const fetchTimereportData = (profile, beginDate, endDate) => {
  return {
    type: FETCH_TIMEREPORT_DATA,
    payload: {profile, beginDate, endDate}
  }
};

export const fetchTimereportDataSuccess = (data) => {
  return {
    type: FETCH_TIMEREPORT_DATA_SUCCESS,
    payload: data
  }
};

export const showTimereportMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showReportMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideReportLoader = () => {
  return {
    type: HIDE_REPORT_LOADER,
  };
};

export const fetchUsers = () => {
  return {
    type: FETCH_ALL_USERS
  };
};
export const fetchUsersSuccess = (users) => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: users
  }
};