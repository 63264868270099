import {
  FETCH_TIMEREPORT_DATA_SUCCESS,
  SHOW_MESSAGE,
  HIDE_REPORT_LOADER,
  FETCH_ALL_USERS_SUCCESS
} from 'constants/ActionTypes';


const INIT_STATE = {
  timereportData: undefined,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TIMEREPORT_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        timereportData: action.payload,
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_REPORT_LOADER: {
      return {...state, loader: false}
    }
    case FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        allUsers: action.payload,
      }
    }
    default:
      return state;
  }
}
