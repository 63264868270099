import {
  FETCH_ALL_USER_SUCCESS,
  GET_ALL_USER,
  GET_UNSELECTED_ALL_USER,
  HANDLE_REQUEST_CLOSE,
  HIDE_USER_LOADER,
  ON_ALL_USER_SELECT,
  ON_USER_CLOSE,
  ON_USER_SELECT,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_USER_SEARCH,
  FILTER_USER,
  SET_CLIENT_SUCCESS,
  SET_DEPARTMENT_MANAGER_SUCCESS,
  SET_ACTIVE_SUCCESS,
  DEACTIVATE_SUCCESS
} from 'constants/ActionTypes';


const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  loader: false,
  noContentFoundMessage: 'No user found in selected folder',
  selectedSectionId: 1,
  drawerState: false,
  searchUser: '',
  filterOption: 1,
  allUser: undefined,
  userList: [], //only for prod
  //allUser: data,
  //userList: data,
  selectedUser: null,
  selectedUsers: 0,
  addUserState: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_USER_SUCCESS: {
      const userList = action.payload.map(user => {
          return {...user, selected: false}
        }
      );
      return {
        ...state,
        loader: false,
        allUser: userList,
        userList: userList,
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case ON_USER_SELECT: {
      action.payload.selected = !action.payload.selected;
      let selectedUsers = 0;
      const userList = state.userList.map(user => {
          if (user.selected) {
            selectedUsers++;
          }
          if (user.id === action.payload.id) {            
            return action.payload;
          } else {
            return user;
          }
        }
      );
      return {
        ...state, INIT_STATE,
        selectedUsers: selectedUsers,
        userList: userList
      };
    }
    case GET_ALL_USER: {
      let userList = state.allUser.map((user) => user ? {
        ...user,
        selected: true
      } : user);
      return {
        ...state,
        selectedUsers: userList.length,
        allUser: userList,
        userList: userList
      }
    }
    case GET_UNSELECTED_ALL_USER: {
      let userList = state.allUser.map((user) => user ? {
        ...user,
        selected: false
      } : user);
      return {
        ...state,
        selectedUsers: 0,
        allUser: userList,
        userList: userList
      }
    }
    case ON_ALL_USER_SELECT: {
      const selectAll = state.selectedUsers < state.userList.length;
      let selectedUsers = 0;
      let userList = [];
      if (selectAll) {
        userList = state.allUser.map((user) => user ? {
          ...user,
          selected: true
        } : user);
        selectedUsers = userList.length;
      } else {
        userList = state.allUser.map((user) => user ? {
          ...user,
          selected: true
        } : user);
        selectedUsers = 0;
      }
      return {
        ...state,
        selectedUsers: selectedUsers,
        allUser: userList,
        userList: userList
      }
    }
    case UPDATE_USER_SEARCH: {
      return {...state, searchUser: action.payload}
    }
    case HANDLE_REQUEST_CLOSE: {
      return {...state, showMessage: false}
    }
    case ON_TOGGLE_DRAWER: {
      return {...state, drawerState: !state.drawerState}
    }
    case HIDE_USER_LOADER: {
      return {...state, loader: false}
    }
    case FILTER_USER: {
      const {filterOption} = state;
      let userList = [];
      if (action.payload === '') {
        const filterUser = state.allUser;
        if (filterOption === 1) {
          userList = filterUser
        }
      } else {
        const filterUser = state.allUser.filter((user) =>
            user.displayName.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
        if (filterOption === 1) {
          userList = filterUser
        }
      }
      return {...state, userList: userList}
    }
    case SET_CLIENT_SUCCESS: {
      const newList = state.userList.map((user) => {
        if(user.id === action.payload){
          user.client = true;
        }
        return user;
      });
      return {...state, userList: newList}
    }

    case SET_DEPARTMENT_MANAGER_SUCCESS: {
      const newList = state.userList.map((user) => {
        if(user.id === action.payload){
          user.departmentManager = true;
        }
        return user;
      });
      return {...state, userList: newList}
    }

    case SET_ACTIVE_SUCCESS: {
      const newList = state.userList.map((user) => {
        if(user.id === action.payload){
          user.active = true;
        }
        return user;
      });
      return {...state, userList: newList}
    }

    case DEACTIVATE_SUCCESS: {
      const newList = state.userList.map((user) => {
        if(user.id === action.payload){
          user.active = false;
          user.client = false;
          user.departmentManager = false;
        }
        return user;
      });
      return {...state, userList: newList}
    }

    default:
      return state;
  }
}
