import enLang from './entries/en-US';
import saLang from './entries/ru_RU';
import uaLang from './entries/uk_UK';

const AppLocale = {
  ru: saLang,
  ua: uaLang,
  en: enLang
};

export default AppLocale;