import React from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import IntlMessages from 'util/IntlMessages';
import {
    Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';


const Profile = (input) => {

    const {onSaveProfile, onProfileClose, open, user} = input;

    return (

        <Formik
            enableReinitialize={true}
            initialValues={{ id: user.authUser, displayName: user.displayName, file: null }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                onProfileClose();
                onSaveProfile(
                    {
                        ...values,
                        photoName: user.photoName,
                        roles: user.roles,
                        email: user.email
                    });
                resetForm();
            }}

            validationSchema={Yup.object().shape({

            })}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    isValid,
                    setFieldValue
                } = props;


                return (

                    <Modal className="modal-box" toggle={onProfileClose} isOpen={open}>
                        <ModalHeader className="modal-box-header bg-primary text-white">

                                <IntlMessages id="popup.profile"/>
                            <IconButton className="text-white"
                                        onClick={onProfileClose}>
                                <CloseIcon/>
                            </IconButton>
                        </ModalHeader>
                        <Form>
                            <div className="modal-box-content">
                                <div className="row no-gutters">
                                    <Box width="100%">
                                        <Box width="100%">
                                            <TextField
                                                required
                                                id="displayName"
                                                label={<IntlMessages id="profile.name"/>}
                                                value={values.displayName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                helperText={(errors.subject && touched.subject) && errors.subject}
                                                margin="none"
                                            />
                                        </Box>
                                        <Box width="100%">

                                            <label><IntlMessages id="profile.avatar"/></label><br />
                                            <input id="file" name="file" type="file" label="Choose image" onChange={(event) => {
                                                setFieldValue("file", event.currentTarget.files[0]);
                                            }} />
                                        </Box>

                                    </Box>
                                </div>
                            </div>
                            <div className="modal-box-footer d-flex flex-row">
                                <Button type="submit" disabled={!(isValid && dirty)} variant="contained" color="primary" ><IntlMessages id="profile.save"/></Button>
                            </div>
                        </Form>
                    </Modal>

                );
            }}
        </Formik>

    );
};

export default Profile;