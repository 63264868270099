import {
  FETCH_ALL_SERVICE,
  FETCH_ALL_SERVICE_SUCCESS,
  GET_ALL_SERVICE,
  GET_UNSELECTED_ALL_SERVICE,
  HANDLE_REQUEST_CLOSE,
  HIDE_SERVICE_LOADER,
  ON_ALL_SERVICE_SELECT,
  ON_SERVICE_CLOSE,
  ON_SERVICE_SELECT,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_SERVICE_SEARCH,
  FILTER_SERVICE,
  SET_ACTIVE_SERVICE,
  DEACTIVATE_SERVICE,
  SET_ACTIVE_SERVICE_SUCCESS,
  DEACTIVATE_SERVICE_SUCCESS,
  ON_ADD_SERVICE,
  ON_ADD_SERVICE_SUCCESS,
  ON_SAVE_SERVICE,
  ON_SAVE_SERVICE_SUCCESS
} from 'constants/ActionTypes';


export const onAddService = () => {
  return {
    type: ON_ADD_SERVICE,
    payload: ''
  };
};
export const onAddServiceSuccess = () => {
  return {
    type: ON_ADD_SERVICE_SUCCESS,
    payload: ''
  };
};
export const onSaveService = (data) => {
  return {
    type: ON_SAVE_SERVICE,
    payload: data
  };
};

export const onSaveServiceSuccess = (data) => {
  return {
    type: ON_SAVE_SERVICE_SUCCESS,
    payload: data
  };
};

export const fetchServices = () => {
  return {
    type: FETCH_ALL_SERVICE,
    payload: ''
  };
};

export const fetchServicesSuccess = (services) => {
  return {
    type: FETCH_ALL_SERVICE_SUCCESS,
    payload: services
  }
};
export const showServiceMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const onServiceSelect = (data) => {
  return {
    type: ON_SERVICE_SELECT,
    payload: data
  };
};


export const onServiceClose = () => {
  return {
    type: ON_SERVICE_CLOSE,
    payload: ''
  };
};

export const getAllService = () => {
  return {
    type: GET_ALL_SERVICE,
    payload: ''
  };
};
export const getUnselectedAllService = () => {
  return {
    type: GET_UNSELECTED_ALL_SERVICE,
    payload: ''
  };
};
export const onAllServiceSelect = () => {
  return {
    type: ON_ALL_SERVICE_SELECT,
    payload: ''
  };
};
export const updateServiceSearch = (search) => {
  return {
    type: UPDATE_SERVICE_SEARCH,
    payload: search
  };
};
export const onToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER,
  };
};
export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE,
  };
};
export const hideServiceLoader = () => {
  return {
    type: HIDE_SERVICE_LOADER,
  };
};

export const filterService = (serviceName) => {
  return {
    type: FILTER_SERVICE,
    payload: serviceName
  };
};

export const setActive = (uid) => {
  return {
    type: SET_ACTIVE_SERVICE,
    payload: uid
  }
};

export const deactivate = (uid) => {
  return {
    type: DEACTIVATE_SERVICE,
    payload: uid
  }
};

export const setActiveSuccess = (uid) => {
  return {
    type: SET_ACTIVE_SERVICE_SUCCESS,
    payload: uid
  }
};

export const deactivateSuccess = (uid) => {
  return {
    type: DEACTIVATE_SERVICE_SUCCESS,
    payload: uid
  }
};