import {
  FETCH_ALL_SERVICE_SUCCESS,
  GET_ALL_SERVICE,
  GET_UNSELECTED_ALL_SERVICE,
  HANDLE_REQUEST_CLOSE,
  HIDE_SERVICE_LOADER,
  ON_ALL_SERVICE_SELECT,
  ON_SERVICE_CLOSE,
  ON_SERVICE_SELECT,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_SERVICE_SEARCH,
  FILTER_SERVICE,
  SET_ACTIVE_SERVICE_SUCCESS,
  DEACTIVATE_SERVICE_SUCCESS,
  ON_ADD_SERVICE_SUCCESS,
  ON_SAVE_SERVICE_SUCCESS
} from 'constants/ActionTypes';


const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  loader: false,
  noContentFoundMessage: 'No service found in selected folder',
  selectedSectionId: 1,
  drawerState: false,
  searchService: '',
  filterOption: 1,
  allService: undefined,
  serviceList: [], //only for prod
  //allService: data,
  //serviceList: data,
  selectedService: null,
  selectedServices: 0,
  addServiceState: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_SERVICE_SUCCESS: {
      const serviceList = action.payload.map(service => {
          return {...service, selected: false}
        }
      );
      return {
        ...state,
        loader: false,
        allService: serviceList,
        serviceList: serviceList,
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case ON_SERVICE_SELECT: {
      action.payload.selected = !action.payload.selected;
      let selectedServices = 0;
      const serviceList = state.serviceList.map(service => {
          if (service.selected) {
            selectedServices++;
          }
          if (service.id === action.payload.id) {            
            return action.payload;
          } else {
            return service;
          }
        }
      );
      return {
        ...state, INIT_STATE,
        selectedServices: selectedServices,
        serviceList: serviceList
      };
    }
    case GET_ALL_SERVICE: {
      let serviceList = state.allService.map((service) => service ? {
        ...service,
        selected: true
      } : service);
      return {
        ...state,
        selectedServices: serviceList.length,
        allService: serviceList,
        serviceList: serviceList
      }
    }
    case GET_UNSELECTED_ALL_SERVICE: {
      let serviceList = state.allService.map((service) => service ? {
        ...service,
        selected: false
      } : service);
      return {
        ...state,
        selectedServices: 0,
        allService: serviceList,
        serviceList: serviceList
      }
    }
    case ON_ALL_SERVICE_SELECT: {
      const selectAll = state.selectedServices < state.serviceList.length;
      let selectedServices = 0;
      let serviceList = [];
      if (selectAll) {
        serviceList = state.allService.map((service) => service ? {
          ...service,
          selected: true
        } : service);
        selectedServices = serviceList.length;
      } else {
        serviceList = state.allService.map((service) => service ? {
          ...service,
          selected: true
        } : service);
        selectedServices = 0;
      }
      return {
        ...state,
        selectedServices: selectedServices,
        allService: serviceList,
        serviceList: serviceList
      }
    }
    case UPDATE_SERVICE_SEARCH: {
      return {...state, searchService: action.payload}
    }
    case HANDLE_REQUEST_CLOSE: {
      return {...state, showMessage: false}
    }
    case ON_TOGGLE_DRAWER: {
      return {...state, drawerState: !state.drawerState}
    }
    case HIDE_SERVICE_LOADER: {
      return {...state, loader: false}
    }
    case FILTER_SERVICE: {
      const {filterOption} = state;
      let serviceList = [];
      if (action.payload === '') {
        const filterService = state.allService;
        if (filterOption === 1) {
          serviceList = filterService
        }
      } else {
        const filterService = state.allService.filter((service) =>
            service.displayName.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
        if (filterOption === 1) {
          serviceList = filterService
        }
      }
      return {...state, serviceList: serviceList}
    }

    case SET_ACTIVE_SERVICE_SUCCESS: {
      const newList = state.serviceList.map((service) => {
        if(service.id === action.payload){
          service.status = true;
        }
        return service;
      });
      return {...state, serviceList: newList}
    }

    case DEACTIVATE_SERVICE_SUCCESS: {
      const newList = state.serviceList.map((service) => {
        if(service.id === action.payload){
          service.status = false;
        }
        return service;
      });
      return {...state, serviceList: newList}
    }

    case ON_ADD_SERVICE_SUCCESS: {
      return {...state, addSprintState: true};
    }

    case ON_SAVE_SERVICE_SUCCESS: {
      let isNew = true;
      const serviceList = state.allService.map((service) => {
        if (service.id === action.payload.id) {
          isNew = false;
          return action.payload
        } else {
          return service
        }
      });
      if (isNew) {
        serviceList.push({...action.payload, status: true, selected: false});
      }
      return {
        ...state,
        alertMessage: isNew ? 'Sprint Added Successfully' : 'Sprint Updated Successfully',
        showMessage: true,
        serviceList: serviceList,
        allService: serviceList,
      }
    }

    default:
      return state;
  }
}
