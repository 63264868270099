import {
  FETCH_ALL_SPRINT,
  FETCH_ALL_SPRINT_SUCCESS,
  FILTER_SPRINT,
  GET_ALL_SPRINT,
  GET_UNSELECTED_ALL_SPRINT,
  HANDLE_REQUEST_CLOSE,
  HIDE_SPRINT_LOADER,
  ON_ADD_SPRINT,
  ON_ALL_SPRINT_SELECT,
  ON_SPRINT_CLOSE,
  ON_SPRINT_SELECT,
  ON_DELETE_SPRINT,
  ON_DELETE_SELECTED_SPRINT,
  ON_FILTER_OPTION_SELECT,
  ON_SAVE_SPRINT,
  ON_SAVE_SPRINT_SUCCESS,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_SPRINT_SEARCH,
  FETCH_ACTIVE_SPRINT,
  FETCH_ACTIVE_SPRINT_SUCCESS,
  ON_SPRINT_MENU_ITEM_SELECT
} from 'constants/ActionTypes';


export const fetchSprints = () => {
  return {
    type: FETCH_ALL_SPRINT,
    payload: ''
  };
};

export const fetchSprintsSuccess = (sprint) => {
  return {
    type: FETCH_ALL_SPRINT_SUCCESS,
    payload: sprint
  }
};

export const fetchActiveSprint = () => {
  return {
    type: FETCH_ACTIVE_SPRINT,
    payload: ''
  };
};

export const fetchActiveSprintSuccess = (sprint) => {
  return {
    type: FETCH_ACTIVE_SPRINT_SUCCESS,
    payload: sprint
  }
};

export const showSprintMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const onSprintSelect = (data) => {
  return {
    type: ON_SPRINT_SELECT,
    payload: data
  };
};


export const onAddSprint = () => {
  return {
    type: ON_ADD_SPRINT,
    payload: ''
  };
};
export const onSprintClose = () => {
  return {
    type: ON_SPRINT_CLOSE,
    payload: ''
  };
};
export const onFilterOptionSelect = (filter, profile) => {
  return {
    type: ON_FILTER_OPTION_SELECT,
    payload: {filter, profile}
  };
};
export const onSaveSprint = (data) => {
  return {
    type: ON_SAVE_SPRINT,
    payload: data
  };
};
export const onSaveSprintSuccess = (data) => {
  return {
    type: ON_SAVE_SPRINT_SUCCESS,
    payload: data
  };
};
export const onDeleteSprint = (data) => {
  return {
    type: ON_DELETE_SPRINT,
    payload: data
  };
};
export const onDeleteSelectedSprint = (allSprint) => {
  return {
    type: ON_DELETE_SELECTED_SPRINT,
    payload: allSprint
  };
};
export const filterSprint = (userName) => {
  return {
    type: FILTER_SPRINT,
    payload: userName
  };
};
export const getAllSprint = () => {
  return {
    type: GET_ALL_SPRINT,
    payload: ''
  };
};
export const getUnselectedAllSprint = () => {
  return {
    type: GET_UNSELECTED_ALL_SPRINT,
    payload: ''
  };
};
export const onAllSprintSelect = () => {
  return {
    type: ON_ALL_SPRINT_SELECT,
    payload: ''
  };
};
export const updateSprintSearch = (search) => {
  return {
    type: UPDATE_SPRINT_SEARCH,
    payload: search
  };
};
export const onToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER,
  };
};
export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE,
  };
};
export const hideSprintLoader = () => {
  return {
    type: HIDE_SPRINT_LOADER,
  };
};

export const onSprintMenuItemSelect = (sprint, tasks) => {
  return {
    type: ON_SPRINT_MENU_ITEM_SELECT,
    payload: {sprint, tasks}
  };
};