import {
  FETCH_ALL_SPRINT_SUCCESS,
  FILTER_SPRINT,
  GET_ALL_SPRINT,
  GET_UNSELECTED_ALL_SPRINT,
  HANDLE_REQUEST_CLOSE,
  HIDE_SPRINT_LOADER,
  ON_ADD_SPRINT,
  ON_ALL_SPRINT_SELECT,
  ON_SPRINT_CLOSE,
  ON_SPRINT_SELECT,
  ON_FILTER_OPTION_SELECT,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_SPRINT_SEARCH,
  ON_DELETE_SPRINT,
  ON_DELETE_SELECTED_SPRINT,
  ON_SAVE_SPRINT_SUCCESS,
  FETCH_ACTIVE_SPRINT_SUCCESS,
  ON_SPRINT_MENU_ITEM_SELECT
} from 'constants/ActionTypes';


const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  loader: true,
  noContentFoundMessage: 'No sprint found in selected folder',
  selectedSectionId: 1,
  drawerState: false,
  searchSprint: '',
  filterOption: 1,
  allSprint: [],
  sprintList: [], //only for prod
  //allSprint: data,
  //sprintList: data,
  selectedSprint: null,
  selectedSprints: 0,
  addSprintState: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_SPRINT_SUCCESS: {
      const sprintList = action.payload.map(sprint => {
          return {...sprint, selected: false}
        }
      );
      return {
        ...state,
        loader: false,
        allSprint: sprintList,
        sprintList: sprintList,
      }
    }
    case FETCH_ACTIVE_SPRINT_SUCCESS: {
      const sprintList = action.payload.map(sprint => {
            return {...sprint, selected: false}
          }
      );
      return {
        ...state,
        loader: false,
        sprintList: sprintList,
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case ON_SPRINT_SELECT: {
      action.payload.selected = !action.payload.selected;
      let selectedSprints = 0;
      const sprintList = state.sprintList.map(sprint => {
          if (sprint.selected) {
            selectedSprints++;
          }
          if (sprint.id === action.payload.id) {            
            return action.payload;
          } else {
            return sprint;
          }
        }
      );
      return {
        ...state, INIT_STATE,
        selectedSprints: selectedSprints,
        sprintList: sprintList
      };
    }
    case ON_ADD_SPRINT: {
      return {...state, addSprintState: true};
    }
    case ON_SPRINT_CLOSE: {
      return {...state, addSprintState: false};
    }
    case ON_DELETE_SPRINT: {
      return {
        ...state,
        alertMessage: 'Sprint Deleted Successfully',
        showMessage: true,
        allSprint: state.allSprint.filter((sprint) => sprint.id !== action.payload.id),
        sprintList: state.allSprint.filter((sprint) => sprint.id !== action.payload.id),
      }
    }
    case ON_DELETE_SELECTED_SPRINT: {
      const sprints = state.allSprint.filter((sprint) => !sprint.selected);
      return {
        ...state,
        alertMessage: 'Sprint Deleted Successfully',
        showMessage: true,
        allSprint: sprints,
        sprintList: sprints,
        selectedSprints: 0
      }
    }
    
    case ON_FILTER_OPTION_SELECT: {
      let selectedSectionId = 0;
      let filterOption = 1;
      let sprintList = [];
      let filterSprint = [];
      switch (action.payload.filter.id) {
        case 1: {
          selectedSectionId = action.payload.filter.id;
          filterOption = action.payload.filter.id;
          filterSprint = state.allSprint;
          if(state.searchSprint !== '') {
             sprintList = filterSprint.filter((sprint) =>
                sprint.title.toLowerCase().indexOf(state.searchSprint.toLowerCase()) > -1);
          }else
            sprintList = filterSprint;
          break;
        }
        case 2: {
          selectedSectionId = action.payload.filter.id;
          filterOption = action.payload.filter.id;
          filterSprint = state.allSprint.filter((sprint) => {
            if(action.payload.profile.sprints_favourite !== undefined
                && action.payload.profile.sprints_favourite[sprint.id] !== undefined
                && action.payload.profile.sprints_favourite[sprint.id])
              return sprint;
            else return false;
          });
          if(state.searchSprint !== '') {
             sprintList = filterSprint.filter((sprint) =>
                sprint.title.toLowerCase().indexOf(state.searchSprint.toLowerCase()) > -1);
          }else
            sprintList = filterSprint;
          break;
        }

        default: {
          selectedSectionId = action.payload.id;
          filterOption = action.payload.id;
          sprintList = state.allSprint;
          break;
        }
      }
      return {
        ...state, selectedSectionId, filterOption, sprintList, drawerState: false
      };
    }
    case ON_SAVE_SPRINT_SUCCESS: {
      let isNew = true;
      const sprintList = state.allSprint.map((sprint) => {
        if (sprint.id === action.payload.id) {
          isNew = false;
          return action.payload
        } else {
          return sprint
        }
      });
      if (isNew) {        
        sprintList.push({...action.payload, status: true, selected: false});
      }
      return {
        ...state,
        alertMessage: isNew ? 'Sprint Added Successfully' : 'Sprint Updated Successfully',
        showMessage: true,
        sprintList: sprintList,
        allSprint: sprintList,
      }
    }
    case FILTER_SPRINT: {
      const {filterOption} = state;
      let sprintList = [];
      if (action.payload === '') {
        const filterSprint = state.allSprint;
        if (filterOption === 1) {
          sprintList = filterSprint
        } else if (filterOption === 2) {
          sprintList = filterSprint.filter((sprint) => sprint.starred);
        }
      } else {
        const filterSprint = state.allSprint.filter((sprint) =>
          sprint.title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
        if (filterOption === 1) {
          sprintList = filterSprint
        } else if (filterOption === 2) {
          sprintList = filterSprint.filter((sprint) => sprint.starred);
        }
      }
      return {...state, sprintList: sprintList}
    }
    case GET_ALL_SPRINT: {
      let sprintList = state.allSprint.map((sprint) => sprint ? {
        ...sprint,
        selected: true
      } : sprint);
      return {
        ...state,
        selectedSprints: sprintList.length,
        allSprint: sprintList,
        sprintList: sprintList
      }
    }
    case GET_UNSELECTED_ALL_SPRINT: {
      let sprintList = state.allSprint.map((sprint) => sprint ? {
        ...sprint,
        selected: false
      } : sprint);
      return {
        ...state,
        selectedSprints: 0,
        allSprint: sprintList,
        sprintList: sprintList
      }
    }
    case ON_ALL_SPRINT_SELECT: {
      const selectAll = state.selectedSprints < state.sprintList.length;
      let selectedSprints = 0;
      let sprintList = [];
      if (selectAll) {
        sprintList = state.allSprint.map((sprint) => sprint ? {
          ...sprint,
          selected: true
        } : sprint);
        selectedSprints = sprintList.length;
      } else {
        sprintList = state.allSprint.map((sprint) => sprint ? {
          ...sprint,
          selected: true
        } : sprint);
        selectedSprints = 0;
      }
      return {
        ...state,
        selectedSprints: selectedSprints,
        allSprint: sprintList,
        sprintList: sprintList
      }
    }
    case UPDATE_SPRINT_SEARCH: {
      return {...state, searchSprint: action.payload}
    }
    case HANDLE_REQUEST_CLOSE: {
      return {...state, showMessage: false}
    }
    case ON_TOGGLE_DRAWER: {
      return {...state, drawerState: !state.drawerState}
    }
    case HIDE_SPRINT_LOADER: {
      return {...state, loader: false}
    }
    case ON_SPRINT_MENU_ITEM_SELECT: {
      const updatedSprints = state.sprintList.map((sprint) => {
        if(sprint.id === action.payload.sprint.id){

          if(sprint.tasks){
            action.payload.tasks.map((task) => sprint.tasks[task.id] = {...task});
          } else {
            const tasksMap = {};
            action.payload.tasks.map((task) => tasksMap[task.id] = true);
            sprint.tasks = tasksMap;
          }
          return sprint;
        }else return sprint;
      });
      return {
        ...state,
        sprintList: updatedSprints
      }
    }
    default:
      return state;
  }
}
