import {
  FETCH_ALL_PROJECT_SUCCESS,
  FILTER_PROJECT,
  GET_ALL_PROJECT,
  GET_UNSELECTED_ALL_PROJECT,
  HANDLE_REQUEST_CLOSE,
  HIDE_PROJECT_LOADER,
  ON_ADD_PROJECT,
  ON_ALL_PROJECT_SELECT,
  ON_PROJECT_CLOSE,
  ON_PROJECT_SELECT,
  ON_FILTER_OPTION_SELECT,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_PROJECT_SEARCH,
  ON_DELETE_PROJECT,
  ON_DELETE_SELECTED_PROJECT,
  ON_SAVE_PROJECT_SUCCESS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS
} from 'constants/ActionTypes';


const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  loader: true,
  noContentFoundMessage: 'No project found in selected folder',
  selectedSectionId: 1,
  drawerState: false,
  user: {
    name: 'Robert Johnson',
    email: 'robert.johnson@example.com',
    avatar: 'https://via.placeholder.com/150x150'
  },
  searchProject: '',
  filterOption: 1,
  allProject: undefined,
  projectList: [], //only for prod
  //allProject: data,
  //projectList: data,
  selectedProject: null,
  selectedProjects: 0,
  addProjectState: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PROJECT_SUCCESS: {
      const projectList = action.payload.map(project => {
          return {...project, selected: false}
        }
      );
      return {
        ...state,
        loader: false,
        allProject: projectList,
        projectList: projectList,
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case ON_PROJECT_SELECT: {
      action.payload.selected = !action.payload.selected;
      let selectedProjects = 0;
      const projectList = state.projectList.map(project => {
          if (project.selected) {
            selectedProjects++;
          }
          if (project.id === action.payload.id) {            
            return action.payload;
          } else {
            return project;
          }
        }
      );
      return {
        ...state, INIT_STATE,
        selectedProjects: selectedProjects,
        projectList: projectList
      };
    }
    case ON_ADD_PROJECT: {
      return {...state, addProjectState: true};
    }
    case ON_PROJECT_CLOSE: {
      return {...state, addProjectState: false};
    }
    case ON_DELETE_PROJECT: {
      return {
        ...state,
        alertMessage: 'Project Deleted Successfully',
        showMessage: true,
        allProject: state.allProject.filter((project) => project.id !== action.payload.id),
        projectList: state.allProject.filter((project) => project.id !== action.payload.id),
      }
    }
    case ON_DELETE_SELECTED_PROJECT: {
      const projects = state.allProject.filter((project) => !project.selected);
      return {
        ...state,
        alertMessage: 'Project Deleted Successfully',
        showMessage: true,
        allProject: projects,
        projectList: projects,
        selectedProjects: 0
      }
    }
    
    case ON_FILTER_OPTION_SELECT: {
      let selectedSectionId = 0;
      let filterOption = 1;
      let projectList = [];
      let filterProject = [];
      switch (action.payload.filter.id) {
        case 1: {
          selectedSectionId = action.payload.filter.id;
          filterOption = action.payload.filter.id;
          filterProject = state.allProject;
          if(state.searchProject !== '') {
             projectList = filterProject.filter((project) =>
                project.title.toLowerCase().indexOf(state.searchProject.toLowerCase()) > -1);
          }else
            projectList = filterProject;
          break;
        }
        case 2: {
          selectedSectionId = action.payload.filter.id;
          filterOption = action.payload.filter.id;
          filterProject = state.allProject.filter((project) => {
            if(action.payload.profile.projects_favourite !== undefined
                && action.payload.profile.projects_favourite[project.id] !== undefined
                && action.payload.profile.projects_favourite[project.id])
              return project;
            else return false;
          });
          if(state.searchProject !== '') {
             projectList = filterProject.filter((project) =>
                project.title.toLowerCase().indexOf(state.searchProject.toLowerCase()) > -1);
          }else
            projectList = filterProject;
          break;
        }

        default: {
          selectedSectionId = action.payload.id;
          filterOption = action.payload.id;
          projectList = state.allProject;
          break;
        }
      }
      return {
        ...state, selectedSectionId, filterOption, projectList, drawerState: false
      };
    }
    case ON_SAVE_PROJECT_SUCCESS: {
      let isNew = true;
      const projectList = state.allProject.map((project) => {
        if (project.id === action.payload.id) {
          isNew = false;
          return action.payload
        } else {
          return project
        }
      });
      if (isNew) {        
        projectList.push({...action.payload, status: true, selected: false});
      }
      return {
        ...state,
        alertMessage: isNew ? 'Project Added Successfully' : 'Project Updated Successfully',
        showMessage: true,
        projectList: projectList,
        allProject: projectList,
      }
    }
    case FILTER_PROJECT: {
      const {filterOption} = state;
      let projectList = [];
      if (action.payload === '') {
        const filterProject = state.allProject;
        if (filterOption === 1) {
          projectList = filterProject
        } else if (filterOption === 2) {
          projectList = filterProject.filter((project) => project.starred);
        }
      } else {
        const filterProject = state.allProject.filter((project) =>
          project.title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
        if (filterOption === 1) {
          projectList = filterProject
        } else if (filterOption === 2) {
          projectList = filterProject.filter((project) => project.starred);
        }
      }
      return {...state, projectList: projectList}
    }
    case GET_ALL_PROJECT: {
      let projectList = state.allProject.map((project) => project ? {
        ...project,
        selected: true
      } : project);
      return {
        ...state,
        selectedProjects: projectList.length,
        allProject: projectList,
        projectList: projectList
      }
    }
    case GET_UNSELECTED_ALL_PROJECT: {
      let projectList = state.allProject.map((project) => project ? {
        ...project,
        selected: false
      } : project);
      return {
        ...state,
        selectedProjects: 0,
        allProject: projectList,
        projectList: projectList
      }
    }
    case ON_ALL_PROJECT_SELECT: {
      const selectAll = state.selectedProjects < state.projectList.length;
      let selectedProjects = 0;
      let projectList = [];
      if (selectAll) {
        projectList = state.allProject.map((project) => project ? {
          ...project,
          selected: true
        } : project);
        selectedProjects = projectList.length;
      } else {
        projectList = state.allProject.map((project) => project ? {
          ...project,
          selected: true
        } : project);
        selectedProjects = 0;
      }
      return {
        ...state,
        selectedProjects: selectedProjects,
        allProject: projectList,
        projectList: projectList
      }
    }
    case UPDATE_PROJECT_SEARCH: {
      return {...state, searchProject: action.payload}
    }
    case HANDLE_REQUEST_CLOSE: {
      return {...state, showMessage: false}
    }
    case ON_TOGGLE_DRAWER: {
      return {...state, drawerState: !state.drawerState}
    }
    case HIDE_PROJECT_LOADER: {
      return {...state, loader: false}
    }
    case FETCH_ALL_USERS: {
      return {
        ...state,
        loader: false
      }
    }
    case FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        loader: false,
        allUsers: action.payload,
      }
    }

    default:
      return state;
  }
}
