import arrayMove from 'array-move';
//import taskConversation from 'app/routes/Tasks/data/taskConversation';
//import tasks from 'app/routes/Tasks/data/task';
import {firebase} from '../firebase/firebase';
import {
  FETCH_ALL_TASK_CONVERSATION_SUCCESS,
  FETCH_ALL_TASK_SUCCESS,
  GET_ALL_TASK,
  GET_IMPORTANT_TASK,
  GET_MARK_AS_START,
  GET_NAV_FILTERS,
  GET_NAV_LABELS,
  GET_STARRED_TASK,
  GET_TASK_CONVERSATION,
  GET_UNIMPORTANT_TASK,
  GET_UNSELECTED_ALL_TASK,
  GET_UNSTARRED_TASK,
  HANDLE_REQUEST_CLOSE,
  ON_DELETE_TIMELOG_SUCCESS,
  ON_HIDE_LOADER,
  ON_LABEL_MENU_ITEM_SELECT,
  ON_LABEL_SELECT,
  ON_LABEL_UPDATE,
  ON_OPTION_MENU_ITEM_SELECT,
  ON_OPTION_MENU_SELECT,
  ON_SORTEND,
  ON_ADD_LINK_SUCCESS,
  ON_TASK_ADD_SUCCESS,
  ON_TASK_CHECKED,
  ON_TASK_SELECT,
  ON_TASK_UPDATE_SUCCESS,
  ON_TOGGLE_DRAWER,
  SEARCH_TASK,
  SELECT_ALL_TASK,
  SET_CURRENT_TASK_NULL,
  SHOW_MESSAGE,
  SHOW_401,
  SHOW_TASKS,
  UPDATE_SEARCH,
  FETCH_ALLOWED_PROJECTS_SUCCESS,
  FETCH_TASK_DETAIL,
  FETCH_TASK_DETAIL_SUCCESS,
  FETCH_TASK_LOG_SUCCESS,
  ADD_SPENT_TIME_SUCCESS,
  FETCH_TASK_TIMELOG_SUCCESS,
  ON_UPLOAD_FILE_SUCCESS,
  ON_DELETE_FILE,
  ON_DELETE_LINK,
  FETCH_ALL_USERS_SUCCESS,
  GET_CUSTOM_FILTERS,
  ON_SUBMIT_COMMENT_SUCCESS,
  ON_UPDATE_COMMENT_SUCCESS,
  ON_APPROVE_COMMENT_SUCCESS
} from 'constants/ActionTypes'

const INIT_STATE = {
  searchTask: '',
  alertMessage: '',
  loader: true,
  showMessage: false,
  optionName: 'None',
  drawerState: false,
  allTasks: [],
  //allTasks: tasks,
  currentTask: null,
  user: {
    name: 'Robert Johnson',
    email: 'robert.johnson@example.com',
    avatar: "https://via.placeholder.com/150x150"
  },
  selectedTasks: 0,
  labelMenuState: false,
  optionMenuState: false,
  tasks: [],
  //tasks: tasks,
  filter: -1,
  taskConversation: [],
  //taskConversation,
  conversation: null,
  spentTime: 0,
  links: [],
  acl_forbidden: false
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_SORTEND: {
      return {
        ...state, tasks: arrayMove(state.tasks, action.payload.oldIndex, action.payload.newIndex)
      }
    }
    case ON_LABEL_SELECT: {
      return {
        ...state,
        labelMenuState: !state.labelMenuState
      }
    }
    case ON_OPTION_MENU_SELECT: {
      return {
        ...state,
        optionMenuState: !state.optionMenuState
      }
    }
    case ON_OPTION_MENU_ITEM_SELECT: {
      return {...state}
    }
    case SELECT_ALL_TASK: {
      let tasks = state.allTasks.map((task) => task ? {
        ...task,
        selected: true
      } : task);

      return {
        ...state,
        selectedTasks: tasks.length,
        allTasks: tasks,
        optionName: 'All',
        tasks: tasks
      }
    }
    case GET_ALL_TASK: {
      return {
        ...state,
        currentTask: null,
        tasks: state.allTasks,
        filter: -1,
      }
    }
    case GET_UNSELECTED_ALL_TASK: {

      let tasks = state.allTasks.map((task) => task ? {
        ...task,
        selected: false
      } : task);
      return {
        ...state,
        selectedTasks: 0,
        allTasks: tasks,
        optionName: 'None',
        tasks: tasks
      }
    }
    case GET_STARRED_TASK: {
      let selectedTasks = 0;
      let tasks = state.allTasks.map((task) => {
        if (task.starred) {
          selectedTasks++;
          return {...task, selected: true};
        }
        return {...task, selected: false}
      });
      return {
        ...state,
        selectedTasks: selectedTasks,
        allTasks: tasks,
        optionName: 'Stared',
        tasks: tasks.filter(task => !task.deleted)
      }
    }
    case GET_UNSTARRED_TASK: {
      let selectedTasks = 0;
      let tasks = state.allTasks.map((task) => {
        if (!task.starred) {
          selectedTasks++;
          return {...task, selected: true};
        }
        return {...task, selected: false}
      });
      return {
        ...state,
        selectedTasks: selectedTasks,
        allTasks: tasks,
        optionName: 'UnStarred',
        tasks: tasks.filter(task => !task.deleted)
      }
    }
    case GET_IMPORTANT_TASK: {
      let selectedTasks = 0;
      let tasks = state.allTasks.map((task) => {
        if (task.important) {
          selectedTasks++;
          return {...task, selected: true};
        }
        return {...task, selected: false}
      });
      return {
        ...state,
        selectedTasks: selectedTasks,
        allTasks: tasks,
        optionName: 'Important',
        tasks: tasks.filter(task => !task.deleted)
      }
    }
    case GET_UNIMPORTANT_TASK: {
      let selectedTasks = 0;
      let tasks = state.allTasks.map((task) => {
        if (!task.important) {
          selectedTasks++;
          return {...task, selected: true};
        }
        return {...task, selected: false}
      });

      return {
        ...state,
        selectedTasks: selectedTasks,
        allTasks: tasks,
        optionName: 'Unimportant',
        tasks: tasks.filter(task => !task.deleted)
      }
    }

    case ON_LABEL_UPDATE: {
      if (action.payload.data.labels.includes(action.payload.label.id)) {
        action.payload.data.labels.splice(action.payload.data.labels.indexOf(action.payload.label.id), 1);
      } else {
        action.payload.data.labels = action.payload.data.labels.concat(action.payload.label.id);
      }
      const tasks = state.allTasks.map(task => {
            if (task.id === action.payload.data.id) {
              return action.payload.data;
            } else {
              return task;
            }
          }
      );

      return {
        ...state,
        alertMessage: 'Label Updated Successfully',
        showMessage: true,
        currentTask: action.payload,
        allTasks: tasks,
        tasks: tasks,
      }
    }
    case ON_TASK_UPDATE_SUCCESS: {
      let currentTask = state.currentTask;
      Object.keys(action.payload.newValue).map((field) => {
        currentTask[field] = action.payload.newValue[field];
      });
      /*const tasks = state.allTasks.map(task => {
        if (task.id === action.payload.id) {
          Object.keys(action.payload.newValue).map((field) => {
            task[field] = action.payload[field];
          });
          return task;
        } else {
          return task;
        }
      });*/
      return {
        ...state,
        alertMessage: 'Задача успешно сохранена на сервере.',
        showMessage: true,
        currentTask: currentTask,
      }
    }
    case ON_DELETE_TIMELOG_SUCCESS: {
      const timelog = action.payload.task.timelog.filter(log => {
            if (action.payload.log.createdAt === log.createdAt && action.payload.log.user.id === log.user.id) {
              return false;
            } else {
              return log;
            }
          }
      );
      const spentTime = parseInt(state.currentTask.spentTime ? state.currentTask.spentTime : 0) - parseInt(action.payload.log.minutes);
      return {
        ...state,
        alertMessage: 'Log Deleted Successfully',
        showMessage: true,
        currentTask: {...state.currentTask, timelog: timelog, spentTime}
      }
    }

    case GET_NAV_LABELS: {
      const filterMails = state.allTasks.filter(
          task => action.payload.profile.tasks_labels !== undefined
                && action.payload.profile.tasks_labels[task.id] !== undefined
                && action.payload.profile.tasks_labels[task.id] === action.payload.label.title
          );
      return {
        ...state,
        loader: true,
        currentTask: null,
        tasks: filterMails
      }
    }
    case GET_NAV_FILTERS: {
      const closedStatuses = ['Cancelled', 'Closed'];
      const filterMails = state.allTasks.filter(task => {
        if (action.payload.filter.id === 0
            && action.payload.profile.tasks_favourite !== undefined
            && action.payload.profile.tasks_favourite[task.id] !== undefined
            && action.payload.profile.tasks_favourite[task.id]) {
          return task
        } else if (action.payload.filter.id === 1 && task.priority === 'Critical') {
          return task
        } else if (action.payload.filter.id === 2 && task.dueDate) {
          const now = Date.now();
          const one_day = 1000 * 60 * 60 * 24;
          if(task.dueDate - now < 1 * one_day)
            return task;
          else return false;
        } else if (action.payload.filter.id === 3 && task.status === 'Open') {
          return task
        } else if (action.payload.filter.id === 4 && task.status === 'InWork') {
          return task
        } else if (action.payload.filter.id === 5 && task.status === 'Clarification') {
          return task
        } else if (action.payload.filter.id === 6 && task.status === 'Checking') {
          return task
        } else if (action.payload.filter.id === 7
            && action.payload.sprint.tasks !== undefined
            && action.payload.sprint.tasks[task.id]) {
          return task
        } else if (action.payload.filter.id === 8 && closedStatuses.includes(task.status)) {
          return task
        } else if (action.payload.filter.id === 9 && !closedStatuses.includes(task.status)) {
          return task
        } else return false;
      });
      return {
        ...state,
        loader: true,
        currentTask: null,
        filter: action.payload.filter.id,
        tasks: filterMails
      }
    }

    case SEARCH_TASK: {
      if (action.payload === '') {
        return {...state, tasks: state.allTasks.filter((task) => !task.deleted)};
      } else {
        const searchTasks = state.allTasks.filter((task) =>
            !task.deleted && (task.subject.toLowerCase().indexOf(action.payload.toLowerCase()) > -1 || task.description && task.description.toLowerCase().indexOf(action.payload.toLowerCase()) > -1));
        return {...state, tasks: searchTasks}
      }
    }
    case SHOW_TASKS: {
      return {...state}
    }
    case GET_TASK_CONVERSATION: {
      return {...state}
    }
    case ON_TASK_CHECKED: {
      action.payload.selected = !action.payload.selected;
      let selectedTasks = 0;
      const tasks = state.tasks.map(task => {
            if (task.selected) {
              selectedTasks++;
            }
            if (task.id === action.payload.id) {
              if (task.selected) {
                selectedTasks++;
              }
              return action.payload;
            } else {
              return task;
            }
          }
      );
      return {
        ...state,
        selectedTasks: selectedTasks,
        tasks: tasks
      }
    }
    case ON_TASK_ADD_SUCCESS: {
      const {data, path} = action.payload;
      const win = window.open('/app/tasks/'+data.project.code+'/'+data.id, '_blank');
      //win.focus();
      if(data.author && data.author.id === localStorage.getItem('user_id') && path === "/app/tasks-created")
        return {
          ...state,
          tasks: state.allTasks.concat(data),
          allTasks: state.allTasks.concat(data)
        }
      if(data.assigned && data.assigned.id === localStorage.getItem('user_id') && path === "/app/tasks-assigned")
        return {
          ...state,
          tasks: state.allTasks.concat(data),
          allTasks: state.allTasks.concat(data)
        }
      return {
        ...state
      }
    }
    case ON_TASK_SELECT: {
      let conversationList = state.taskConversation.find((conversation) => conversation.id === action.payload.id);
      if (conversationList) {
        conversationList = conversationList.conversationData;
      } else {
        conversationList = [];
      }
      return {
        ...state,
        currentTask: action.payload,
        loader: true,
        conversation: conversationList
      }
    }
    case FETCH_TASK_DETAIL: {
      return {
        ...state,
        loader: true,
        acl_forbidden: false
      }
    }
    case FETCH_TASK_DETAIL_SUCCESS: {
      return {
        ...state,
        currentTask: action.payload,
        loader: false,
        allTasks: [],
        tasks: []
      }
    }
    case FETCH_TASK_LOG_SUCCESS: {
      return {
        ...state,
        currentTask: {...state.currentTask, log: action.payload},
      }
    }
    case FETCH_TASK_TIMELOG_SUCCESS: {
      return {
        ...state,
        currentTask: {...state.currentTask, timelog: action.payload},
      }
    }
    case SET_CURRENT_TASK_NULL: {
      return {...state, currentTask: null}
    }
    case UPDATE_SEARCH: {
      return {...state, searchTask: action.payload}
    }
    case ON_TOGGLE_DRAWER: {
      return {...state, drawerState: !state.drawerState}
    }
    case HANDLE_REQUEST_CLOSE: {
      return {...state, showMessage: false, addTask: false, labelMenuState: false, optionMenuState: false}
    }
    case ON_HIDE_LOADER: {
      return {...state, loader: false}
    }
    case FETCH_ALL_TASK_SUCCESS: {
      let tasks = action.payload.tasks.map((task) => task ? {
        ...task,
        selected: false
      } : task);
      return {
        ...state,
        allTasks: tasks,
        tasks: tasks,
        loader: false,
        updateTimestamp: action.payload.updateTimestamp,
        updateType: action.payload.updateType
      }
    }
    case FETCH_ALL_TASK_CONVERSATION_SUCCESS: {
      return {
        ...state,
        taskConversation: action.payload
      }
    }
    case FETCH_ALLOWED_PROJECTS_SUCCESS: {
      return {
        ...state,
        allProjects: action.payload
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }

    case SHOW_401: {
      return {
        ...state,
        acl_forbidden: true
      }
    }

    case ADD_SPENT_TIME_SUCCESS: {
      const spentTime = parseInt(state.currentTask.spentTime ? state.currentTask.spentTime : 0) + parseInt(action.payload.minutes);
      const task = {...state.currentTask, spentTime};
      return {
        ...state,
        currentTask: task
      }
    }

    case ON_UPLOAD_FILE_SUCCESS: {
      const files = state.currentTask.files ? [...state.currentTask.files, ...action.payload.file] : [...action.payload.file];
      return {
        ...state,
        currentTask: {...state.currentTask, files},
      }
    }

    case ON_DELETE_FILE: {
      const files = action.payload.task.files.filter((file) => file.URL !== action.payload.file.URL);
      return {
        ...state,
        currentTask: {...action.payload.task, files},
      }
    }

    case ON_ADD_LINK_SUCCESS: {
      const links = state.links.concat(
        action.payload
      );
      return {
        ...state,
        currentTask: {...state.currentTask, links},
      }
    }

    case ON_DELETE_LINK: {
      const links = action.payload.task.links.filter((link) => link.id !== action.payload.link.id);
      return {
        ...state,
        currentTask: {...action.payload.task, links},
      }
    }
    case FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        allUsers: action.payload,
      }
    }
    case GET_CUSTOM_FILTERS: {
      //const closedStatuses = ['Cancelled', 'Closed'];

      const filterMails = state.allTasks.filter(task => {
        if(Object.getOwnPropertyNames(action.payload.filter.projects).length && !action.payload.filter.projects[task.project.id])
          return false;
        console.log('projects filtered');
        if(Object.getOwnPropertyNames(action.payload.filter.author).length && !action.payload.filter.author[task.author.id])
          return false;
        console.log('author filtered');
        if(Object.getOwnPropertyNames(action.payload.filter.assigned).length && !action.payload.filter.assigned[task.assigned.id])
          return false;
        console.log('assigned filtered');
        if(Object.getOwnPropertyNames(action.payload.filter.priority).length && !action.payload.filter.priority[task.priority])
          return false;
        console.log('priority filtered');
        if(Object.getOwnPropertyNames(action.payload.filter.type).length && !action.payload.filter.type[task.type])
          return false;
        console.log('types filtered');
        if(Object.getOwnPropertyNames(action.payload.filter.status).length && !action.payload.filter.status[task.status])
          return false;
        console.log('statuses filtered');

        return task;
      });
      return {
        ...state,
        loader: true,
        currentTask: null,
        filter: action.payload.filter.subject ? action.payload.filter.subject : 'choose',
        tasks: filterMails
      }
    }

    case ON_SUBMIT_COMMENT_SUCCESS: {
      let newConversation = state.currentTask.conversation.concat(
          {
            ...action.payload,
            createdAt: firebase.firestore.Timestamp.fromDate(action.payload.createdAt)
          }
      );
      return {
        ...state,
        currentTask: {...state.currentTask, conversation: newConversation},
        alertMessage: 'Комментарий успешно сохранен на сервере.',
        showMessage: true,
      }
    }

    case ON_UPDATE_COMMENT_SUCCESS: {
      let newConversation = action.payload;
      return {
        ...state,
        currentTask: {...state.currentTask, conversation: newConversation},
        alertMessage: 'Успешно обновлено на сервере.',
        showMessage: true,
      }
    }

    case ON_APPROVE_COMMENT_SUCCESS: {
      let newConversation = action.payload;
      return {
        ...state,
        currentTask: {...state.currentTask, conversation: newConversation},
        alertMessage: 'Успешно обновлено на сервере.',
        showMessage: true,
      }
    }

    default:
      return state;
  }
}
