import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import {
    auth,
    facebookAuthProvider,
    githubAuthProvider,
    googleAuthProvider,
    twitterAuthProvider,
    database,
    functions,
    firestore,
    fileStorage,
    firebase
} from "../firebase/firebase";
import {
    SIGNIN_FACEBOOK_USER,
    SIGNIN_GITHUB_USER,
    SIGNIN_GOOGLE_USER,
    SIGNIN_TWITTER_USER,
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER,
    UPDATE_AUTH,
    GET_MARK_AS_START,
    ON_LABEL_MENU_ITEM_SELECT,
    ON_SAVE_PROFILE,
    SET_FAVOURITE_PROJECT,
    SET_FAVOURITE_SPRINT,
    ON_FILTER_ADD,
    ON_FILTER_REMOVE,
    ON_RATE_MANAGER
} from "constants/ActionTypes";
import {
    showAuthMessage,
    userSignInSuccess,
    userSignOutSuccess,
    userSignUpSuccess,
    updateProfile,
    onFilterAddSuccess,
    onRateManagerSuccess
} from "actions/Auth";
import {
    showTaskMessage,
} from "actions/Task";
import {
    userFacebookSignInSuccess,
    userGithubSignInSuccess,
    userGoogleSignInSuccess,
    userTwitterSignInSuccess
} from "../actions/Auth";

const createUserWithEmailPasswordRequest = async (email, password, name) => {
    const user = await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => {
            if (authUser.message) return authUser;
            console.log(name);
            auth.currentUser.updateProfile({
                displayName: name,
                email: email
            }).then(function () {
                if (authUser.message) return authUser;
            }).catch(error => error);
            return authUser;
        })
        .catch(error => error);

    if(!user.message) {
        const  createProfile = functions.httpsCallable('createUserProfile');
        createProfile({uid: user.user.uid, displayName: name, email: email}).then((result) => {
            console.log(result);
        });
    }

    return user;
}


const signInUserWithEmailPasswordRequest = async (email, password) =>
    await  auth.signInWithEmailAndPassword(email, password)
        .then(authUser => {
            if(authUser.message) return authUser;

            /*const  setAdmin = functions.httpsCallable('addAdminRole');
            setAdmin({uid: authUser.user.uid}).then((result) => {
                console.log(result);
            });*/

            const name = auth.currentUser.displayName;
            authUser.name = name;
            return authUser;

        })
        .catch(error => error);


const signOutRequest = async () =>
    await  auth.signOut()
        .then(authUser => authUser)
        .catch(error => error);


const signInUserWithGoogleRequest = async () => {

    const authData = await auth.signInWithPopup(googleAuthProvider)
        .then(authUser => authUser)
        .catch(function(error) {
            console.log("User did not authorized. Try again. ", error);
            return {
                message: error.message,
            }
        });

    if(authData.message) return authData;

    /*const  setAdmin = functions.httpsCallable('addAdminRole');
    setAdmin({uid: '4vow0T9vp0WO9OGMbCuQ9tOh5gI3'}).then((result) => {
        console.log(result);
    });*/

    return await database.runTransaction(async function(transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(database.collection('users').doc(authData.user.uid)).then(async function(sfDoc) {
            if (!sfDoc.exists) {
                const createProfile = functions.httpsCallable('createUserProfile');
                try {
                    const dbData = await createProfile({uid: authData.user.uid, displayName: authData.user.displayName, email: authData.user.email}).then((result) => {
                        console.log(result);
                        return authData;
                    });
                    return dbData;
                }catch(error) {
                    console.log("User did not created. Try again. ", error);
                    return {
                        message: error.message,
                    }
                };
                //throw "User did not created. Try again.";
            }

            return authData;
        });
    });


    /*const profile = await database.collection('users').doc(result.user.uid).get().then(
        (documentSnapshot) => {
            if(documentSnapshot.exists) {
                return documentSnapshot.data();
            }else return false;
        }
    ).catch(error => error)

    if(!profile) {
        const createProfile = functions.httpsCallable('createUserProfile');
        createProfile({uid: result.user.uid, displayName: result.user.displayName, email: result.user.email}).then((result) => {
            console.log(result);
        });
    }*/

   // return result;
}

const signInUserWithFacebookRequest = async () =>
    await  auth.signInWithPopup(facebookAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithGithubRequest = async () =>
    await  auth.signInWithPopup(githubAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithTwitterRequest = async () =>
    await  auth.signInWithPopup(twitterAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const fetchRolesRequest = async (user) =>
    await  user.getIdTokenResult().then(
        (idTokenResult) => {
            if(!idTokenResult.claims.active)
                return {
                    message: 'You haven\'t been activated yet.',
                };
            return {admin: idTokenResult.claims.admin};
        }
    ).catch(error => error)

const fetchProfileRequest = async (uid) => {

    return await database.collection('users').doc(uid).get().then(
        (documentSnapshot) => {
            if (documentSnapshot.exists) {
                return documentSnapshot.data();
            } else return {
                message: 'There is no profile data yet.',
            };
        }
    ).catch(error => error)
}

const setFavouriteRequest = async (task, favourite) => {
    return await database.collection("users").doc(localStorage.getItem('user_id')).update({
        [`tasks_favourite.${task.id}`]: favourite
    }).then((doc) => console.log("Document successfully updated.")).catch(error => error)
}

const setFavouriteProjectRequest = async (project, favourite) => {
    return await database.collection("users").doc(localStorage.getItem('user_id')).update({
        [`projects_favourite.${project.id}`]: favourite
    }).then((doc) => console.log("Document successfully updated.")).catch(error => error)
}

const setFavouriteSrpintRequest = async (sprint, favourite) => {
    return await database.collection("users").doc(localStorage.getItem('user_id')).update({
        [`sprints_favourite.${sprint.id}`]: favourite
    }).then((doc) => console.log("Document successfully updated.")).catch(error => error)
}


const setLabelRequest = async (label, tasks) => {
    const tasks_labels = {};
    for (let [key, task] of Object.entries(tasks)) {
        tasks_labels[task.id] = label.title;
    }
    await database.collection("users").doc(localStorage.getItem('user_id')).set({tasks_labels}, {merge: true}).then((doc) => console.log("Document successfully updated.")).catch(error => error)
}

const addFilterRequest = async (filter) => {

    const projectsDatabaseFormat = {};
    for (let [key, value] of Object.entries(filter.projects)) {
        projectsDatabaseFormat[value.id] = {
            code: value.code,
            title: value.title
        };
    }

    const authorDatabaseFormat = {};
    for (let [key, value] of Object.entries(filter.author)) {
        authorDatabaseFormat[value.id] = {
            id: value.id,
            displayName: value.displayName,
            email: value.email,
            photoURL: value.photoURL
        };
    }

    const assignedDatabaseFormat = {};
    for (let [key, value] of Object.entries(filter.assigned)) {
        assignedDatabaseFormat[value.id] = {
            id: value.id,
            displayName: value.displayName,
            email: value.email,
            photoURL: value.photoURL
        };
    }

    const typeDatabaseFormat = {};
    for (let [key, value] of Object.entries(filter.type)) {
        typeDatabaseFormat[value] = true;
    }

    const priorityDatabaseFormat = {};
    for (let [key, value] of Object.entries(filter.priority)) {
        priorityDatabaseFormat[value] = true;
    }

    const statusDatabaseFormat = {};
    for (let [key, value] of Object.entries(filter.status)) {
        statusDatabaseFormat[value] = true;
    }

    const filterDBFormat = {
        subject: filter.subject,
        author: authorDatabaseFormat,
        assigned: assignedDatabaseFormat,
        projects: projectsDatabaseFormat,
        type: typeDatabaseFormat,
        priority: priorityDatabaseFormat,
        status: statusDatabaseFormat
    };

    return await database.collection("users").doc(localStorage.getItem('user_id')).update({
        [`filters.${filter.subject}`]: filterDBFormat
    }).then((doc) => {
        console.log("Document successfully updated.");
        return filterDBFormat;
    }).catch(error => error);
};

const removeFilterRequest = async (filterName, filterType) =>
    await database.collection("users").doc(localStorage.getItem('user_id')).update({
        [`filters.${filterName}`]: firestore.FieldValue.delete()
    }).then((doc) => console.log("Document successfully updated.")).catch(error => error);

const rateManagerRequest = async (manager, rating) =>
    await database.collection("users").doc(localStorage.getItem('user_id')).update({
        ['lastManagerRating']: {
            manager,
            rating,
            client: {
                id: localStorage.getItem('user_id'),
                displayName: auth.currentUser.displayName
            },
            rateDate: firestore.FieldValue.serverTimestamp()
        }
    }).then((doc) => {
        console.log("Document successfully updated.");
        return {success: true};
    }).catch(error => error);


const saveProfileRequest = async (profile) => {

    const user = auth.currentUser;

    if(profile.photoName){
        //удаление старой аватарки
        //fileStorage.child('profiles/' + profile.id + '/' + profile.photoName).delete();
    }

    if(profile.file) {
        let newPhotoURL = '';
        // Create a Storage Ref w/ username
        const storageRef = fileStorage.child('profiles/' + profile.id + '/' + profile.file.name);

        // Upload file
        const uploadTask = await storageRef.put(profile.file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        /*uploadTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, function(error) {
            // Handle unsuccessful uploads
        }, function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);



            });
        });*/

        newPhotoURL = await storageRef.getDownloadURL().then((url) => url.toString());

        const  saveProfileFunction = functions.httpsCallable('saveProfile');
        saveProfileFunction({uid: profile.id, client: profile.client, email: profile.email, displayName: profile.displayName, photoURL: newPhotoURL, photoName: profile.file.name}).then((result) => {
            console.log(result);
        });

        user.updateProfile({
            displayName: profile.displayName,
            photoURL: newPhotoURL,
            email: profile.email
        }).then(function() {
            // Update successful.
        }).catch(function(error) {
            // An error happened.
        });

        return {...profile, photoURL: newPhotoURL}

    }else{
        user.updateProfile({
            displayName: profile.displayName,
            email: profile.email
        }).then(function() {
            // Update successful.
        }).catch(function(error) {
            // An error happened.
        });

        const  saveProfileFunction = functions.httpsCallable('saveProfile');
        saveProfileFunction({uid: profile.id, client: profile.client, displayName: profile.displayName, photoURL: profile.photoURL}).then((result) => {
            console.log(result);
        });
        return {...profile}
    }
};



function* createUserWithEmailPassword({payload}) {
    const {email, password, name} = payload;
    try {
        const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password, name);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(
                userSignUpSuccess({
                    authUser: signUpUser.user.uid,
                    displayName: name
                })
            );
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signInUserWithGoogle() {
    try {
        const signUpUser = yield call(signInUserWithGoogleRequest);
        console.log(signUpUser);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(
                userGoogleSignInSuccess({
                    authUser: signUpUser.user.uid,
                    displayName: signUpUser.user.displayName
                })
            );
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithFacebook() {
    try {
        const signUpUser = yield call(signInUserWithFacebookRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userFacebookSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithGithub() {
    try {
        const signUpUser = yield call(signInUserWithGithubRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGithubSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithTwitter() {
    try {
        const signUpUser = yield call(signInUserWithTwitterRequest);
        if (signUpUser.message) {
            if (signUpUser.message.length > 100) {
                yield put(showAuthMessage('Your request has been canceled.'));
            } else {
                yield put(showAuthMessage(signUpUser.message));
            }
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userTwitterSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    try {
        const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            localStorage.setItem('user_id', signInUser.user.uid);
            yield put(userSignInSuccess({
                authUser: signInUser.user.uid,
                displayName: signInUser.name
            }));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser === undefined) {
            localStorage.clear();
            window.location.reload();
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* updateAuthWorker({payload}) {
    try {

        if(payload !== undefined) {
            const roles = yield call(fetchRolesRequest, payload);
            const profile = yield call(fetchProfileRequest, payload.uid);
            /*if (roles.message) {
                yield put(userSignOutSuccess(signOutUser));
                localStorage.clear();
                yield put(showAuthMessage(roles.message));
            } else*/ if (profile.message) {
                yield put(userSignOutSuccess(signOutUser));
                localStorage.clear();
                //window.location.reload();
                yield put(showAuthMessage(profile.message));
            } else {
                yield put(updateProfile({roles: roles, ...profile}));
            }
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* saveProfileWorker({payload}) {
    try {
        if(payload !== undefined && (!payload.file || payload.file.size < 500000)) {
            const newProfile = yield call(saveProfileRequest, payload);
            yield put(updateProfile(newProfile));
        }else yield put(showAuthMessage('The image is too big. Must be less than 500KB.'));
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* getMarkAsStartWorker({payload}) {
    const {task, favourite} = payload;
    try {
        yield call(setFavouriteRequest, task, favourite);
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* setFavouriteProjectWorker({payload}) {
    const {project, favourite} = payload;
    try {
        yield call(setFavouriteProjectRequest, project, favourite);
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* setFavouriteSprintWorker({payload}) {
    const {sprint, favourite} = payload;
    try {
        yield call(setFavouriteSrpintRequest, sprint, favourite);
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* onLabelMenuItemSelectWorker({payload}) {
    const { label , tasks } = payload;
    try {
        yield call(setLabelRequest, label, tasks);
    } catch (error) {
        yield put(showTaskMessage(error));
    }
}

function* onFilterAddWorker({payload}) {
    const { filter } = payload;
    try {
        const result = yield call(addFilterRequest, filter);
        if(result.message)
            yield put(showAuthMessage(result.message));
        else
            yield put(onFilterAddSuccess(result));
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* onFilterRemoveWorker({payload}) {
    const { filterName } = payload;
    try {
        yield call(removeFilterRequest, filterName);
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* onRateManagerWorker({payload}) {
    try {
        const result = yield call(rateManagerRequest, payload.manager, payload.rating);
        if(result.message)
            yield put(showAuthMessage(result.message));
        else
            yield put(onRateManagerSuccess(payload));
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* createUserAccount() {
    yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
    yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
    yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
    yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* updateAuth() {
    yield takeEvery(UPDATE_AUTH, updateAuthWorker);
}

export function* getMarkAsStartWatcher() {
    yield takeEvery(GET_MARK_AS_START, getMarkAsStartWorker);
}

export function* setFavouriteProjectWatcher() {
    yield takeEvery(SET_FAVOURITE_PROJECT, setFavouriteProjectWorker);
}

export function* setFavouriteSprintWatcher() {
    yield takeEvery(SET_FAVOURITE_SPRINT, setFavouriteSprintWorker);
}

export function* onLabelMenuItemSelectWatcher() {
    yield takeEvery(ON_LABEL_MENU_ITEM_SELECT, onLabelMenuItemSelectWorker);
}

export function* onSaveProfile() {
    yield takeEvery(ON_SAVE_PROFILE, saveProfileWorker);
}

export function* onFilterAddWatcher() {
    yield takeEvery(ON_FILTER_ADD, onFilterAddWorker);
}

export function* onFilterRemoveWatcher() {
    yield takeEvery(ON_FILTER_REMOVE, onFilterRemoveWorker);
}

export function* onRateManagerWatcher() {
    yield takeEvery(ON_RATE_MANAGER, onRateManagerWorker);
}


export default function* rootSaga() {
    yield all([fork(signInUser),
        fork(createUserAccount),
        fork(signInWithGoogle),
        fork(signInWithFacebook),
        fork(signInWithTwitter),
        fork(signInWithGithub),
        fork(signOutUser),
        fork(updateAuth),
        fork(getMarkAsStartWatcher),
        fork(onLabelMenuItemSelectWatcher),
        fork(onSaveProfile),
        fork(setFavouriteProjectWatcher),
        fork(setFavouriteSprintWatcher),
        fork(onFilterAddWatcher),
        fork(onFilterRemoveWatcher),
        fork(onRateManagerWatcher),
    ]);
}