import {
  FETCH_ALL_USER,
  FETCH_ALL_USER_SUCCESS,
  GET_ALL_USER,
  GET_UNSELECTED_ALL_USER,
  HANDLE_REQUEST_CLOSE,
  HIDE_USER_LOADER,
  ON_ALL_USER_SELECT,
  ON_USER_CLOSE,
  ON_USER_SELECT,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_USER_SEARCH,
  FILTER_USER,
  SET_CLIENT,
  SET_DEPARTMENT_MANAGER,
  SET_ACTIVE,
  DEACTIVATE,
  SET_CLIENT_SUCCESS,
  SET_DEPARTMENT_MANAGER_SUCCESS,
  SET_ACTIVE_SUCCESS,
  DEACTIVATE_SUCCESS
} from 'constants/ActionTypes';


export const fetchUsers = () => {
  return {
    type: FETCH_ALL_USER,
    payload: ''
  };
};

export const fetchUsersSuccess = (users) => {
  return {
    type: FETCH_ALL_USER_SUCCESS,
    payload: users
  }
};
export const showUserMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const onUserSelect = (data) => {
  return {
    type: ON_USER_SELECT,
    payload: data
  };
};


export const onUserClose = () => {
  return {
    type: ON_USER_CLOSE,
    payload: ''
  };
};

export const getAllUser = () => {
  return {
    type: GET_ALL_USER,
    payload: ''
  };
};
export const getUnselectedAllUser = () => {
  return {
    type: GET_UNSELECTED_ALL_USER,
    payload: ''
  };
};
export const onAllUserSelect = () => {
  return {
    type: ON_ALL_USER_SELECT,
    payload: ''
  };
};
export const updateUserSearch = (search) => {
  return {
    type: UPDATE_USER_SEARCH,
    payload: search
  };
};
export const onToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER,
  };
};
export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE,
  };
};
export const hideUserLoader = () => {
  return {
    type: HIDE_USER_LOADER,
  };
};

export const filterUser = (userName) => {
  return {
    type: FILTER_USER,
    payload: userName
  };
};

export const setClient = (uid) => {
  return {
    type: SET_CLIENT,
    payload: uid
  }
};

export const setDepartmentManager = (uid) => {
  return {
    type: SET_DEPARTMENT_MANAGER,
    payload: uid
  }
};

export const setActive = (uid) => {
  return {
    type: SET_ACTIVE,
    payload: uid
  }
};

export const deactivate = (uid) => {
  return {
    type: DEACTIVATE,
    payload: uid
  }
};

export const setClientSuccess = (uid) => {
  return {
    type: SET_CLIENT_SUCCESS,
    payload: uid
  }
};

export const setDepartmentManagerSuccess = (uid) => {
  return {
    type: SET_DEPARTMENT_MANAGER_SUCCESS,
    payload: uid
  }
};

export const setActiveSuccess = (uid) => {
  return {
    type: SET_ACTIVE_SUCCESS,
    payload: uid
  }
};

export const deactivateSuccess = (uid) => {
  return {
    type: DEACTIVATE_SUCCESS,
    payload: uid
  }
};